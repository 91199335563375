import React from 'react'
import { Button, Spinner } from 'reactstrap'
import { FaDownload } from 'react-icons/fa'

import { useDownloadDoc } from 'api'
import { addNotification } from 'notification'
import { downloadFile } from 'helpers/downloadFile'

export const DownloadCell: React.FC<any> = ({ cell, row }) => {
  const [downloadDoc, { status }] = useDownloadDoc()

  const handleClick = React.useCallback(async () => {
    const docId = row.original.Id
    const label = row.original.DocLibelle
    try {
      const { headers, data } = await downloadDoc({ docId })
      downloadFile(cell.value, data, { type: headers['content-type'] })
    } catch (error) {
      addNotification({
        type: 'danger',
        title: `${label}`,
        message: `${error}`,
      })
    }
  }, [cell.value, downloadDoc, row.original.DocLibelle, row.original.Id])

  return (
    <Button
      size="sm"
      color="light"
      style={{ fontSize: '0.8rem', padding: '0.2rem 0.3rem' }}
      title={cell.value}
      disabled={status === 'loading'}
      onClick={handleClick}
    >
      {status === 'loading' ? <Spinner size="sm" /> : <FaDownload />}
    </Button>
  )
}
