export type Value = string | number
export type Label = string | React.ReactElement
export type Type = 'checkbox' | 'radio'
export type Item = Node | Leaf
export type Node = Leaf & List
export type NodeStatus = 'empty' | 'partial' | 'full'

export interface List {
  tree: Item[]
}

export interface Leaf {
  value: Value
  label: Label
}

export const isNode = (item: Node | Leaf): item is Node => (item as Node).tree !== undefined

export interface ProviderProps {
  type: Type
  tree: Item[]
  checked: Value[]
  onCheck: (checked: Value[]) => void
}

export type TreeSelectProps = ProviderProps & {
  showButtons?: boolean
}

export interface ContextState {
  type: Type
  isNodeExpanded: (value: Value) => boolean
  isLeafChecked: (value: Value) => boolean
  getNodeStatus: (values: Value[]) => NodeStatus
  onNodeExpanded: (value: Value) => () => void
  onNodeChecked: (values: Value[]) => () => void
  onLeafChecked: (value: Value) => () => void
  expandAll: () => void
  collapseAll: () => void
}
