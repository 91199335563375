import React from 'react'
import { Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useDeleteDoc } from 'api'
import { addNotification } from 'notification'

export const DeleteCell: React.FC<any> = ({ cell, row }) => {
  const { t } = useTranslation()
  const [deleteDoc, { status }] = useDeleteDoc()

  const handleClick = React.useCallback(async () => {
    const docId = cell.value
    const label = row.original.DocLibelle
    try {
      await deleteDoc({ docId })
      addNotification({
        type: 'success',
        title: `${label}`,
        message: t('contract.docs.deleteSuccess'),
      })
    } catch (error) {
      addNotification({
        type: 'danger',
        title: `${label}`,
        message: `${error}`,
      })
    }
  }, [cell.value, deleteDoc, row.original.DocLibelle, t])

  if (status === 'loading') return <Spinner size="sm" />

  return <Button close size="sm" style={{ padding: '.2rem' }} onClick={handleClick} />
}
