import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import get from 'lodash/get'
import {isDenied} from 'helpers/userAccessVerification'
import { userManager, SilentRedirectPage, Callback, AccessDenied, Logout } from 'commons'
import { AppRoutes } from './AppRoutes'

const requiredRoles = ['superuser', 'admin:econtrat', 'read:econtrat', 'gazelenergie:quality']

const isExpired = (user: any) => !user || !!user.expired


export const AuthRoutes: FC = () => {
  const isLoadingUser = useSelector((state: any) => state.oidc.isLoadingUser)
  const user = useSelector((state: any) => state.oidc.user)
  const [isRedirecting, setRedirecting] = useState(false)

  useEffect(() => {
    const isCallback = window.location.pathname.startsWith('/callback')
    const isSilentRenew = window.location.pathname.startsWith('/silent_renew')
    if (!isCallback && !isSilentRenew && !user && !isLoadingUser && !isRedirecting) {
      setRedirecting(true)
      userManager.signinRedirect()
    }
  }, [user, isLoadingUser, isRedirecting])

  if (isExpired(user))
    return (
      <Switch>
        <Route path="/silent_renew">
          <SilentRedirectPage />
        </Route>
        <Route path="/">
          <Callback />
        </Route>
      </Switch>
    )

  if (isDenied(user,requiredRoles)) return <AccessDenied />

  return (
    <Switch>
      <Route path="/silent_renew">
        <SilentRedirectPage />
      </Route>

      <Route path="/callback">
        <Callback />
      </Route>

      <Route path="/logout">
        <Logout />
      </Route>

      <Route path="/">
        <AppRoutes />
      </Route>
    </Switch>
  )
}
