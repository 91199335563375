import React, { useCallback, useMemo, useState, useEffect } from 'react'
import difference from 'lodash/difference'
import union from 'lodash/union'
import flatMap from 'lodash/flatMap'

import { ProviderProps, ContextState, Value, isNode } from './Tree.types'

const getStatus = (count: number, total: number) =>
  count === 0 ? 'full' : count === total ? 'empty' : 'partial'

const TreeContext = React.createContext<ContextState | undefined>(undefined)

export const TreeProvider: React.FC<ProviderProps> = ({
  children,
  type,
  tree,
  checked,
  onCheck,
}) => {
  const [expanded, setExpanded] = useState<any[]>([])

  const allNodes = useMemo(() => {
    const getNodes: (node: any) => any = (node) =>
      flatMap(
        node.filter((item: any) => isNode(item)),
        (item: any) => [item.value, ...getNodes((item as any).tree)],
      )
    return getNodes(tree)
  }, [tree])

  useEffect(() => {
    setExpanded(allNodes)
  }, [allNodes])

  const isNodeExpanded = useCallback((value: Value) => expanded.includes(value), [expanded])
  const isLeafChecked = useCallback((value: Value) => checked.includes(value), [checked])

  const onNodeExpanded = useCallback(
    (value: Value) => () => {
      setExpanded(
        expanded.includes(value) ? expanded.filter((x) => x !== value) : [...expanded, value],
      )
    },
    [expanded, setExpanded],
  )

  const onLeafChecked = useCallback(
    (value: Value) => () => {
      if (type === 'radio') {
        onCheck([value])
      } else {
        onCheck(checked.includes(value) ? checked.filter((x) => x !== value) : [...checked, value])
      }
    },
    [checked, onCheck, type],
  )

  const getNodeStatus = useCallback(
    (values: Value[]) => {
      const count = difference(values, checked).length
      return getStatus(count, values.length)
    },
    [checked],
  )

  const onNodeChecked = useCallback(
    (values: Value[]) => () => {
      const diff = difference(values, checked)
      const count = diff.length
      const status = getStatus(count, values.length)
      onCheck(status === 'full' ? diff : union(checked, values))
    },
    [checked, onCheck],
  )

  const expandAll = useCallback(() => {
    setExpanded(allNodes)
  }, [allNodes, setExpanded])

  const collapseAll = useCallback(() => setExpanded([]), [setExpanded])

  const state = {
    type,
    isNodeExpanded,
    isLeafChecked,
    getNodeStatus,
    onNodeExpanded,
    onNodeChecked,
    onLeafChecked,
    expandAll,
    collapseAll,
  }

  return <TreeContext.Provider value={state}>{children}</TreeContext.Provider>
}

export const useTreeContext = () => {
  const context = React.useContext(TreeContext)
  if (context === undefined) {
    throw new Error('useTreeContext must be used within a TreeProvider')
  }
  return context
}
