import React, { Suspense } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { useStyles, useBoolean } from 'hooks'
import { Button, Dialog, ErrorBoundary, Loader } from 'components/atoms'
import { LotCreate } from 'components/sections/LotInfos'
import { LotList } from './LotList'

type Props = {
  done: () => void
}

export const Lots: React.FC<any> = ({ done, ...props }) => {
  const { t } = useTranslation()
  const [isModalOpen, toggleModal, closeModal] = useBoolean(false)

  const css = useStyles((theme: any) => ({
    position: 'relative',
    '>div': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      footer: {
        padding: '1rem',
        paddingTop: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }))

  return (
    <>
      <div css={css} {...props}>
        <div>
          <LotList done={done} />

          <footer>
            <Button size="sm" onClick={toggleModal} color="primary" Icon={<FaPlusCircle />}>
              {t('sidebar.createLot')}
            </Button>
          </footer>
        </div>
      </div>

      <Dialog isOpen={isModalOpen} close={closeModal}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <h5 id="new-contract">{t('sidebar.newLot')}</h5>
            {isModalOpen && <LotCreate done={closeModal} />}
          </Suspense>
        </ErrorBoundary>
      </Dialog>
    </>
  )
}
