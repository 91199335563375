import React from 'react'
import { useTable, CellProps } from 'react-table'
import { Table } from 'reactstrap'
import range from 'lodash/range'

import { InputCell } from './InputCell'

const minRows = 50

export const PasteTable: React.FC<{
  columns: any[]
  data: object[]
  setData: (data: object[]) => any
  isValidCell: (value: string) => boolean
}> = ({ data, setData, columns, isValidCell }) => {
  const allColumns = React.useMemo(
    () => [
      {
        accessor: 'index',
        Cell: ({ row }: CellProps<any>) => <div css={{ textAlign: 'center' }}>{row.index + 1}</div>,
      },
      ...columns.map((col) => ({
        accessor: col.id,
        Header: col.label,
        Cell: (props: CellProps<any>) =>
          props.cell.value === null ? null : (
            <InputCell {...props} invalid={!isValidCell(props.cell.value)} setData={setData} />
          ),
      })),
    ],
    [columns, isValidCell, setData],
  )

  const allData = React.useMemo(() => {
    const columnIds = columns.map((c) => c.id)
    const empty = () => columnIds.reduce((acc, id) => ({ ...acc, [id]: null }), {})
    const moreData = data.length >= minRows ? [] : range(minRows - data.length).map(() => empty())
    return [...data, ...moreData]
  }, [columns, data])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: allColumns,
    data: allData,
  })

  return (
    <Table
      bordered
      size="sm"
      css={{
        margin: 0,
        fontSize: '0.8rem',
        th: {
          position: 'sticky',
          top: 0,
          padding: '0.2rem 0.4rem',
          background: '#fff',
          boxShadow: '0 0.2rem 0.5rem rgba(0, 0, 0, 0.12)',
          borderTop: 'none',
        },
        td: {
          padding: '0.3rem',
        },
      }}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                css={{
                  width: column.id === 'index' ? '2rem' : 'auto',
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
