import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import groupBy from 'lodash/groupBy'
import moment from 'moment'

import { useContracts } from 'api'
import { useTheme } from 'hooks'
import { getFilters } from 'store/selection'
import { EnergyIcon, TreeSelect } from 'components/atoms'
import { ValidationStatusIcon } from 'components/atoms/ValidationStatusIcon'

interface Props {
  value: number | null
  onChange: (id: number) => void
}

export const ContractList: FC<Props> = ({ value, onChange, ...props }) => {
  const theme = useTheme() as any
  const { country, supplier, startYear, endYear, validationStatus } = useSelector(getFilters)
  const { data: contrats } = useContracts()

  const tree = useMemo(() => {
    if (!contrats) return []

    const filtered = contrats.filter((c) => {
      const start = moment(c.CtrDateDebut, 'DD/MM/YYYY').year()
      const end = moment(c.CtrDateFin, 'DD/MM/YYYY').year()
      return (
        [c.CtrIdPays, null].includes(country) &&
        [c.CtrIdFournisseur, null].includes(supplier) &&
        (endYear === null || start <= endYear) &&
        (startYear === null || end >= startYear) && 
        [c.ValidationStatus, null].includes(validationStatus)
      )
    })

    const groups = groupBy(filtered, 'CtrLibellePays')
    const tree = Object.entries(groups).map(([country, contrats]) => ({
      value: country,
      label: country,
      tree: contrats.map((c) => ({
        value: c.id,
        label: (
          <span>
            <EnergyIcon id={c.CtrNrjId} />
            {c.FormattedName} 
            &nbsp;
            <ValidationStatusIcon id={c.ValidationStatus} />
          </span>
        ),
      })),
    }))
    return tree
  }, [contrats, country, endYear, supplier, startYear, validationStatus])

  const checked = value !== null ? [value] : []
  const setChecked = (checked: any[]) => onChange(checked[0])

  return (
    <TreeSelect
      type="radio"
      tree={tree}
      checked={checked}
      onCheck={setChecked}
      css={{
        padding: theme.spacing,
        backgroundColor: theme.gray100,
      }}
      {...props}
    />
  )
}
