import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import findLastIndex from 'lodash/findLastIndex'

import { Tabs, Tab } from 'components/atoms'

interface Props {
  color?: 'light'
  links: {
    label: string | React.ReactElement
    path: string
    matchPath?: string
    disabled?: boolean
  }[]
}

export const RouterTabs: React.FC<Props> = ({ links, ...props }) => {
  const { pathname } = useLocation()
  const matches = links.map((link) => pathname.startsWith(link.matchPath || link.path))
  const index = findLastIndex(matches, (match) => match)

  return (
    <Tabs index={index} {...props}>
      {links.map((link, index) => (
        <Tab key={`${link.path}${index}`} disabled={link.disabled}>
          <Link
            to={link.path}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              color: 'inherit',
              height: '100%',

              maxWidth: 300,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',

              '&:hover': {
                color: 'inherit',
                textDecoration: 'none',
              },
            }}
          >
            {link.label}
          </Link>
        </Tab>
      ))}
    </Tabs>
  )
}
