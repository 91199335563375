import React from 'react'
import { useSelector } from 'react-redux'
import { FaInfoCircle, FaFileAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'hooks'
import { getContractId } from 'store/selection'
import { ContractInfos } from 'components/sections/ContractInfos'
import { ContractValidationStatusInfo } from 'components/sections/ContractInfos/ContractValidationStatusInfo'
import { ContractDocs } from 'components/sections/ContractDocs'
import { Section } from 'components/atoms'
import {ContractValorisation} from 'components/sections/ContractValorisation'
import {isDenied} from 'helpers/userAccessVerification'

export const ContractPageInfos: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const id = useSelector(getContractId)
  const requiredRoles = ['superuser', 'admin:econtrat']
  const user = useSelector((state: any) => state.oidc.user)
  const canValueContract= !isDenied(user,requiredRoles)
  
  if (id === null) return null

  return (
    <div
      css={{
        display: 'flex',
        '>*': {
          width: '50%',
          ':not(:last-child)': {
            marginRight: theme.spacing,
          },
          '>*:not(:last-child)': {
            flex: 0,
            marginBottom: theme.spacing,
          },
        },
      }}
    >
      <div>
        <Section title={t('contract.infos.title')} Icon={FaInfoCircle}>
          <ContractInfos />
        </Section>
        <Section title={t('contract.infos.validationStatusTitle')} Icon={FaInfoCircle}>
          <ContractValidationStatusInfo />
        </Section>
      </div>
      <div>
        <Section title={t('contract.docs.title')} Icon={FaFileAlt}>
          <ContractDocs />
        </Section>
        {canValueContract  && <Section title={t('contract.valorisation.title')} Icon={FaFileAlt}>
          <ContractValorisation />
        </Section>
        }
        
      </div>
    </div>
  )
}
