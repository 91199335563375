import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { superFetch } from 'commons'
import { getReady } from 'store/api'

export const useContracts = () => {
  const ready = useSelector(getReady)
  return useQuery<ContractList, string>(ready && 'contracts', () =>
    superFetch({ url: 'contrats?type=all&gazelEnergie=true' }),
  )
}
