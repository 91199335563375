import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'

import { getReady } from 'store/api'
import { getLotId } from 'store/selection'

export const useLotPerimetre = () => {
  const ready = useSelector(getReady)
  const lotId = useSelector(getLotId)

  const { data } = useQuery<Perimetre, AnyQueryKey>(
    ready && lotId !== null && ['lotPerimetre', { lotId }],
    () =>
      superFetch<Perimetre>({
        url: `lots/${lotId}/perimetre`,
      }),
  )

  return data || []
}

export const useUpdateLotPerimetre = () => {
  const lotId = useSelector(getLotId)

  return useMutation<
    Perimetre,
    {
      lotId: number
      perimetre: {
        PeeId: number
        DateEntree: string | null
        DateSortie: string | null
      }[]
    }
  >(
    ({ lotId, perimetre }) =>
      superFetch<Perimetre>({
        url: `lots/${lotId}/perimetre`,
        method: 'POST',
        body: perimetre,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['lotPerimetre', { lotId }])
      },
    },
  )
}
