import React from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { FormikProps } from 'formik'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'

import { useTheme } from 'hooks'
import { SelectField } from 'components/form'
import { useReferentiel } from 'api'
import {isDenied} from 'helpers/userAccessVerification'

interface Props extends FormikProps<Partial<ContractValidationStatus>> {
  isLoading: boolean
}

// Only superuser and quality user can modify validation status
const requiredRoles = ['superuser', 'gazelenergie:quality']

export const ContractValidationForm: React.FC<Props> = ({ isLoading, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const { ListValidationStatus } = useReferentiel()

  const keys = Object.keys(props.values)

  const user = useSelector((state: any) => state.oidc.user)
  const canModifyValidStatut = !isDenied(user,requiredRoles)

  const isDisabled = !canModifyValidStatut || isLoading || props.isSubmitting || Object.keys(props.touched).length === 0
  const hasError = Object.keys(props.errors).length > 0

  return (
    <Form
      onSubmit={props.handleSubmit}
      css={{
        section: {
          display: 'grid',
          gridGap: theme.spacing,
          gridTemplateColumns: 'repeat(2, 1fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 2,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
            display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.6rem' },
          },
        },
      }}
    >
      <section>
        <SelectField
          name="ValidationStatus"
          label={t('contract.infos.labels.validationStatus')}
          options={ListValidationStatus}
          disabled={!canModifyValidStatut}
        />
      </section>

      <footer>
        <div />
        {
          //Only show the buttons to the quality user
          canModifyValidStatut && (
          <div>
            <Button color="light" onClick={() => props.resetForm()} disabled={isDisabled}>
              {t('global.reset')}
            </Button>

            <Button type="submit" disabled={isDisabled || hasError}>
              {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
              <span>{t('global.validate')}</span>
            </Button>
          </div>
        )
        }
      </footer>
    </Form>
  )
}
