import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Input, Button, Spinner } from 'reactstrap'
import { Trans, useTranslation } from 'react-i18next'

import { useDeleteLot, useLot } from 'api'
import { getContractId, setLotId } from 'store/selection'
import { addNotification } from 'notification'

export const LotDeleteDialog: React.FC<{ lotId: number }> = ({ lotId }) => {
  const { t } = useTranslation()
  const contractId = useSelector(getContractId) as number
  const [deleteLot, { status }] = useDeleteLot(contractId, lotId)
  const history = useHistory()
  const dispatch = useDispatch()
  const lot = useLot(lotId)
  const [input, setInput] = React.useState<string>('')

  if (contractId === null || !lot) return null

  const lotName = lot.Nom
  const match = input === lotName

  return (
    <div css={{ '>*:not(:last-child)': { marginBottom: '1.5rem' } }}>
      <p>
        {t('contract.delete.p1')}
        <br />
        {t('contract.delete.p2')}
        <br />
        <Trans i18nKey="lot.delete.warning">
          Cela supprimera définitivement le lot{' '}
          <strong style={{ whiteSpace: 'nowrap' }}>{{ name: lotName }}</strong>
        </Trans>
      </p>

      <p>
        <Trans i18nKey="lot.delete.confirm">
          Veuillez taper <strong style={{ whiteSpace: 'nowrap' }}>{{ name: lotName }}</strong> pour
          confirmer
        </Trans>
      </p>

      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled={status === 'loading'}
      />

      <Button
        color={match ? 'danger' : 'light'}
        disabled={!match || status === 'loading'}
        onClick={async () => {
          try {
            await deleteLot()

            history.push(`/contrat/${contractId}`)
            dispatch(setLotId(null))

            addNotification({
              type: 'success',
              message: t('lot.delete.success', { name: lotName }),
            })
          } catch (error) {
            addNotification({
              type: 'danger',
              title: t('lot.delete.errorTitle'),
              message: (error as any).toString(),
            })
          }
        }}
        css={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {status === 'loading' ? (
          <>
            <Spinner size="sm" style={{ marginRight: '.6rem' }} />
            <span>{t('lot.delete.deleting')}</span>
          </>
        ) : (
          <span>{t('lot.delete.submit')}</span>
        )}
      </Button>
    </div>
  )
}
