import React from 'react'
import { CustomInput } from 'reactstrap'
import { FaAngleRight } from 'react-icons/fa'
import flatten from 'lodash/flatten'
import { motion } from 'framer-motion'

import { useTheme } from 'hooks'
import { Node, Item, isNode, Value } from './Tree.types'
import { TreeList } from './TreeList'
import { useTreeContext } from './TreeContext'

const getLeafs: (tree: Item[]) => Value[] = (tree) =>
  flatten(tree.map((item) => (isNode(item) ? getLeafs(item.tree) : item.value)))

export const TreeNode: React.FC<Node> = React.memo(({ value, label, tree }) => {
  const inputRef = React.useRef<any>()
  const theme = useTheme() as any
  const ctx = useTreeContext()
  const isExpanded = ctx.isNodeExpanded(value)

  const leafs = getLeafs(tree)
  const status = ctx.getNodeStatus(leafs)

  const checked = status !== 'empty'

  const backgroundColor =
    status === 'full' ? theme.gold : status === 'partial' ? theme.cyan : '#fff'

  const borderColor = status === 'full' ? theme.gold : status === 'partial' ? theme.cyan : '#adb5bd'

  React.useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.indeterminate = inputRef.current && status === 'partial'
  }, [status])

  return (
    <div>
      <div css={{ display: 'flex' }}>
        <motion.div
          css={{
            color: theme.gray700,
            lineHeight: 1,
            width: 18,
            height: 18,
          }}
          initial={false}
          animate={{ rotate: isExpanded ? 90 : 0.1 }}
          onClick={ctx.onNodeExpanded(value)}
        >
          <FaAngleRight role="button" size={18} css={{ verticalAlign: 'baseline' }} />
        </motion.div>

        {ctx.type === 'radio' ? (
          <div
            css={{ marginLeft: 4, cursor: 'pointer' }}
            role="button"
            onClick={ctx.onNodeExpanded(value)}
            onKeyPress={ctx.onNodeExpanded(value)}
            tabIndex={0}
          >
            {label}
          </div>
        ) : (
          <CustomInput
            type={ctx.type}
            id={value.toString()}
            label={label}
            checked={checked}
            onChange={ctx.onNodeChecked(leafs)}
            innerRef={inputRef}
            css={{
              marginLeft: 4,
              'label:before': {
                backgroundColor: `${backgroundColor} !important`,
                borderColor: `${borderColor} !important`,
              },
            }}
          />
        )}
      </div>

      <TreeList tree={tree} isExpanded={isExpanded} style={{ marginLeft: 18 }} />
    </div>
  )
})
