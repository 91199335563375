import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactQueryConfigProvider } from 'react-query'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from '@emotion/react'
import { OidcProvider } from 'redux-oidc'
import { Provider } from 'react-redux'

import { i18n, userManager } from 'commons'
import { theme } from 'styles/theme'
import { queryConfig } from 'api'
import store from 'configureStore'

import { AuthRoutes } from 'components/routes/AuthRoutes'

import fr from 'i18n/fr.json'
import en from 'i18n/en.json'

i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('en', 'translation', en)

const App = () => {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
              <Router>
                <AuthRoutes />
              </Router>
            </OidcProvider>
          </Provider>
        </I18nextProvider>
      </ThemeProvider>
    </ReactQueryConfigProvider>
  )
}

export default App
