import React from 'react'
import { FormFeedback } from 'reactstrap'
import { useFormikContext, useField } from 'formik'

import { Select } from './Select'

export const SelectField: React.FC<{
  name: string
  reference?: {
    Id: number
    Nom: string
  }[]
}> = ({ name, reference = [] }) => {
  const { isSubmitting } = useFormikContext()
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name)

  const handleChange = React.useCallback(
    (value) => {
      setValue(value)
      setTouched(true)
    },
    [setTouched, setValue],
  )

  return (
    <div>
      <Select
        value={reference.find((item) => item.Id === Number(value)) || null}
        items={reference}
        invalid={(!!error && !!touched) || reference.length === 0}
        onChange={handleChange}
        disabled={isSubmitting}
      />

      {error && touched && <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>}
    </div>
  )
}
