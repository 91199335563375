import { useQuery, useMutation, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getLotId } from 'store/selection'

import { useLot } from 'api'

export const useLotTarif = () => {
  const ready = useSelector(getReady)
  const lotId = useSelector(getLotId) as number
  const lot = useLot(lotId)
  const { data } = useQuery<Tarif, AnyQueryKey>(
    ready &&
      lotId !== null &&
      lot && ['lotTarif', { lotId, tarif: lot.Tarif, hrs: lot.Horosaisonnier }],
    () =>
      superFetch({
        url: `lots/${lotId}/tarif`,
      }),
  )
  return data
}

interface UpdatePayload {
  TarifId: number
  Prix: any[]
}

export const useUpdateLotTarif = () => {
  const lotId = useSelector(getLotId)

  return useMutation<Tarif, UpdatePayload>(
    (payload) =>
      superFetch({
        url: `lots/${lotId}/tarif`,
        method: 'POST',
        body: payload,
      }),
    {
      useErrorBoundary: false,
      throwOnError: false,
    },
  )
}
