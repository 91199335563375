import React, { useState, FC } from 'react'
import { Button } from 'reactstrap'
import { CallbackComponent } from 'redux-oidc'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import { useLocation } from 'react-router-dom'

import userManager from '../userManager'

export const Callback: FC = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (['/silent_renew', '/callback', 'logout'].includes(pathname)) return
    localStorage.setItem('econtract-location', pathname)
  }, [pathname])

  const { t } = useTranslation()
  const [redirect, setRedirect] = useState(false)

  if (redirect) return <Redirect to="/" push />

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => setRedirect(true)}
      errorCallback={(error) => console.warn('Login CallbackError', (error as any).message)}
    >
      <div
        css={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner color="primary" />

        <div css={{ marginTop: '1rem' }}>
          <Button color="link" onClick={() => (window.location.href = '/')}>
            {t('access.redirection')}
          </Button>
        </div>
      </div>
    </CallbackComponent>
  )
}
