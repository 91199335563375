import React, { FC } from 'react'
import { Spinner } from 'reactstrap'

export const Loader: FC = (props) => {
  return (
    <div
      css={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'grid',
        '>*': {
          margin: 'auto',
        },
      }}
      {...props}
    >
      <Spinner color="primary" />
    </div>
  )
}
