import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import flatMap from 'lodash/flatMap'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import compact from 'lodash/compact'
import uniqBy from 'lodash/uniqBy'
import differenceBy from 'lodash/differenceBy'
import matchSorter from 'match-sorter'

import React, { FC, Suspense, useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Button, Input, Spinner } from 'reactstrap'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import { useTranslation } from 'react-i18next'
import Table from 'react-table-v6'
import { transparentize } from 'polished'
import { useRect } from '@reach/rect'

import {
  FaAngleRight,
  FaAngleDown,
  FaAngleUp,
  FaFileImport,
  FaFileExcel,
  FaCheck,
  FaRedo,
  FaTimes,
} from 'react-icons/fa'

import { useCurrentContract, useCurrentLot, useBoolean } from 'hooks'
import {
  useContractPerimetre,
  useLotPerimetre,
  useUpdateLotPerimetre,
  useOrganisation,
  isUor,
} from 'api'
import { ErrorBoundary, Loader, EnergyIcon, Dialog } from 'components/atoms'
import { downloadCSV } from 'helpers/downloadFile'
import { translations } from 'helpers/react-table'
import { addNotification } from 'notification'

import { LotPerimetreImport } from './LotPerimetreImport'

const rowHeight = 32

const useTree = (ctr: ContractItem | null) => {
  const orga = useOrganisation()

  const contractPerimetre = useContractPerimetre()
  const cpIds = contractPerimetre.map((pee) => pee.id)

  const [expanded, setExpanded] = useState<string[]>([])

  useEffect(() => {
    if (orga) setExpanded([`uor_${orga.id}`])
  }, [orga])

  const nodes = useMemo(() => {
    if (!ctr || !orga) return null

    const createNode: (node: TreeNode) => Node = (node) => {
      if (isUor(node)) {
        const uors = node.UorEnfants?.map(createNode) ?? []
        const sites = node.UorSites?.map(createNode) ?? []
        const children = [...uors, ...sites].filter(({ children }) => !isEmpty(children))
        return {
          value: `uor_${node.id}`,
          label: node.UorNom,
          ...(!isEmpty(children) && { children }),
        }
      }

      return {
        value: `sph_${node.id}`,
        label: node.SphNom,
        children:
          node.SitPees?.filter(
            (pee) => cpIds.includes(pee.id) && pee.PeeNrjId === ctr.CtrNrjId,
          ).map((pee) => ({
            value: `${pee.id}`,
            label: pee.PeeClef,
          })) ?? [],
      }
    }

    return [createNode(orga)]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctr, orga])

  const pees = useMemo(() => {
    if (!ctr || !orga) return {}

    const createLeafs: (node: TreeNode) => { [id: string]: Pee } = (node) =>
      isUor(node)
        ? merge({}, ...flatMap([...(node.UorEnfants ?? []), ...(node.UorSites ?? [])], createLeafs))
        : keyBy(
            node.SitPees?.filter((pee) => pee.PeeNrjId === ctr.CtrNrjId).map((pee) => ({
              ...pee,
              PeeSphNom: node.SphNom,
            })),
            'id',
          )

    return createLeafs(orga)
  }, [ctr, orga])

  return { nodes, pees, expanded, setExpanded }
}

const useFilter = (nodes: Node[] | null) => {
  const [value, setValue] = useState('')
  const [filtered, setFiltered] = useState(nodes)
  useEffect(() => {
    setFiltered(nodes)
  }, [nodes])

  const filterTree = useCallback(
    debounce((value: string) => {
      if (!nodes) return

      if (!value) {
        setFiltered(nodes)
        return
      }

      const filterNodes = (nodes: Node[], node: Node) => {
        const match =
          (node.label as string).toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1

        if (match) {
          nodes.push(node)
        } else {
          const children = node.children?.reduce(filterNodes, []) ?? []
          if (children.length > 0) {
            nodes.push({ ...node, children })
          }
        }

        return nodes
      }

      setFiltered(nodes.reduce(filterNodes, []))
    }, 500),
    [nodes],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    filterTree(e.target.value)
  }

  return { filtered, value, onChange }
}

const PerimetreTree: FC<{
  perimetre: Perimetre
  updatePerimetre: (p: Perimetre) => void
  contract: ContractItem | null
  width: number
  disabled: boolean
}> = ({ perimetre, updatePerimetre, contract, width, disabled }) => {
  const { t } = useTranslation()
  const { nodes, pees, expanded, setExpanded } = useTree(contract)
  const { filtered, value, onChange } = useFilter(nodes)

  const checked = useMemo(() => perimetre.map((pee) => pee.id.toString()), [perimetre])

  const setChecked = useCallback(
    (values: string[]) => {
      updatePerimetre(compact(values.map((id) => pees[id])))
    },
    [pees, updatePerimetre],
  )

  if (!contract || !filtered) return null

  return (
    <div
      css={{
        width,
        height: '100%',
        display: 'grid',
        gridGap: 15,
        gridTemplateRows: 'auto 1fr',
        fontSize: 14,
        '.tree-container': {
          position: 'relative',
          height: '100%',
          borderRight: '1px solid #ced4da',
          '.react-checkbox-tree': {
            display: 'block',
            position: 'absolute',
            height: '100%',
            width: '100%',
            overflow: 'auto',
            '>ol': {
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            },
          },
          '.rct-options': {
            position: 'sticky',
            top: 0,
            margin: 0,
            paddingRight: 10,
            zIndex: 1,
            width: 80,
            left: '100%',
            button: {
              background: '#ddd',
              borderRadius: 3,
              svg: {
                marginBottom: 2,
              },
            },
          },
          '.rct-node .rct-node-icon': {
            display: 'none',
          },
          '.rct-node-leaf .rct-node-icon': {
            display: 'inline',
            marginRight: -8,
          },
          '.rct-text label': {
            display: 'flex',
            alignItems: 'center',
          },
          '.rct-title': {
            whiteSpace: 'nowrap',
            maxWidth: 300,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      }}
    >
      <Input value={value} onChange={onChange} placeholder={t('lot.scope.searchPlaceholder')} />

      <div className="tree-container">
        <CheckboxTree
          checkModel="leaf"
          nativeCheckboxes={true}
          showNodeIcon={true}
          disabled={disabled}
          showExpandAll={true}
          nodes={filtered}
          checked={checked}
          expanded={expanded}
          onCheck={setChecked}
          onExpand={setExpanded}
          icons={{
            expandOpen: <FaAngleDown size={16} />,
            expandClose: <FaAngleRight size={16} color="#999" />,
            expandAll: <FaAngleDown size={16} />,
            collapseAll: <FaAngleUp size={16} />,
            leaf: <EnergyIcon id={contract.CtrNrjId} />,
          }}
        />
      </div>
    </div>
  )
}

const PerimetreTable: FC<{
  initialPerimetre: Perimetre
  perimetre: Perimetre
  removePees: (pees: Pee[]) => void
  disabled: boolean
}> = ({ initialPerimetre, perimetre, removePees, disabled }) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRect(ref, false)
  const pageSize = rect && Math.floor(rect.height / rowHeight) - 2

  const columns = useMemo(
    () => [
      {
        Cell: ({ original }: any) => (
          <Button
            color="light"
            size="sm"
            disabled={disabled}
            onClick={() => removePees([original])}
            style={{ padding: '0.15rem 0.4rem' }}
          >
            <FaTimes color="#555" />
          </Button>
        ),
        sortable: false,
        resizable: false,
        filterable: false,
        width: 40,
      },
      {
        Header: t('global.country'),
        accessor: 'LibellePays',
        Filter: TextFilter,
        filterAll: true,
        filterMethod: defaultFilterMethod,
      },
      {
        Header: t('global.site'),
        accessor: 'PeeSphNom',
        Filter: TextFilter,
        filterAll: true,
        filterMethod: defaultFilterMethod,
      },
      {
        Header: t('global.pee'),
        accessor: 'PeeClef',
        Filter: TextFilter,
        filterAll: true,
        filterMethod: defaultFilterMethod,
      },
      {
        Header: 'Segment',
        accessor: 'PeeSegment',
        Filter: TextFilter,
        filterAll: true,
        filterMethod: defaultFilterMethod,
      },
    ],
    [disabled, removePees, t],
  )

  const removedCount = differenceBy(initialPerimetre, perimetre, 'id').length
  const addedCount = differenceBy(perimetre, initialPerimetre, 'id').length

  return (
    <div style={{ height: '100%' }} ref={ref}>
      {pageSize && (
        <>
          <Table
            data={perimetre}
            columns={columns}
            pageSize={pageSize}
            showPageSizeOptions={false}
            showPagination={perimetre.length > pageSize}
            {...translations(t)}
            noDataText=""
            filterable={true}
            defaultSorted={[
              { id: 'LibellePays', desc: false },
              { id: 'PeeSphNom', desc: false },
              { id: 'PeeClef', desc: false },
            ]}
            css={(theme: any) => ({
              '.rt-thead': { fontWeight: 700 },
              '.-filters': {
                fontSize: '0.875rem !important',
              },
              '.rt-th': {
                height: 'auto !important',
                '&.-sort-asc': {
                  boxShadow: `inset 0 3px 0 0 ${transparentize(0.6, theme.green)} !important`,
                },
                '&.-sort-desc': {
                  boxShadow: `inset 0 -3px 0 0 ${transparentize(0.6, theme.green)} !important`,
                },
              },
              '.rt-th, .rt-td': {
                height: 32,
                display: 'flex',
                alignItems: 'center',
                padding: '4px 6px',
              },
            })}
          />

          <div
            css={{
              textAlign: 'right',
              marginTop: '1rem',
              '>span:not(:first-of-type):before': {
                content: '" - "',
              },
            }}
          >
            <span>
              {perimetre.length} {t('scope.pee', { count: perimetre.length })}
            </span>
            {removedCount > 0 && (
              <span className="text-danger font-weight-bold">
                {removedCount} {t('scope.removed', { count: removedCount })}
              </span>
            )}
            {addedCount > 0 && (
              <span className="text-secondary font-weight-bold">
                {addedCount} {t('scope.added', { count: addedCount })}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const Perimetre = () => {
  const { t } = useTranslation()
  const contract = useCurrentContract()
  const lot = useCurrentLot()
  const initialPerimetre = useLotPerimetre()

  const [perimetre, setPerimetre] = useState<Perimetre>([])

  useEffect(() => {
    setPerimetre(initialPerimetre)
  }, [initialPerimetre])

  const [isImportModalOpen, toggleImportModal, closeImportModal] = useBoolean(false)

  const removePees = useCallback(
    (ids: number[]) => {
      setPerimetre(perimetre.filter((pee) => !ids.includes(pee.id)))
    },
    [perimetre, setPerimetre],
  )

  const togglePeeModal = useCallback(
    (pees: Pee[]) => {
      removePees(pees.map((pee) => pee.id))
    },
    [removePees],
  )

  const updatePerimetre = useCallback((p: Perimetre) => {
    setPerimetre(p)
  }, [])

  const [mutate, { status }] = useUpdateLotPerimetre()
  const isUpdating = status === 'loading'

  const addPees = useCallback(
    (pees: Pee[]) => {
      setPerimetre(uniqBy([...perimetre, ...pees], (pee) => pee.id))
    },
    [perimetre, setPerimetre],
  )

  const reset = useCallback(() => {
    if (initialPerimetre) setPerimetre(initialPerimetre)
  }, [initialPerimetre])

  useEffect(() => {
    reset()
  }, [initialPerimetre, reset])

  const handleExport = useCallback(() => {
    const header = [t('global.country'), t('global.site'), t('global.pee'), 'Segment']
    const body = perimetre.map((pee) => {
      return [pee.LibellePays, pee.PeeSphNom, `"=""${pee.PeeClef}"""`, pee.PeeSegment]
    })
    const content = [header, ...body].map((row) => row.join(';')).join('\n')
    const filename = `${t('lot.scope.title')} ${lot?.nom ?? ''}.csv`
    downloadCSV(filename, content)
  }, [lot, perimetre, t])

  const handlesubmit = useCallback(async () => {
    if (!lot) return
    try {
      await mutate({
        lotId: lot.id,
        perimetre: perimetre.map((p) => ({
          PeeId: p.id,
          DateEntree: p.DateEntree,
          DateSortie: p.DateSortie,
        })),
      })
      addNotification({
        type: 'success',
        message: t('lot.scope.success'),
      })
    } catch (error) {
      addNotification({
        type: 'danger',
        title: t('lot.scope.errorTitle'),
        message: (error as any).toString(),
      })
    }
  }, [lot, mutate, perimetre, t])

  return (
    <>
      <div
        css={{
          height: '100%',
          display: 'grid',
          gridGap: 30,
          gridTemplateColumns: 'auto 1fr',
          '.ptree': { width: 500, position: 'relative' },
          '.ptable': {
            display: 'grid',
            gridGap: 30,
            gridTemplateRows: '1fr auto',
            footer: {
              display: 'flex',
              justifyContent: 'space-between',
              '>div>*:not(:last-child)': { marginRight: 10 },
              button: { '>*:not(:last-child)': { margin: '0 8px 3px 0' } },
            },
          },
        }}
      >
        <div className="ptree">
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <PerimetreTree
                {...{ perimetre, updatePerimetre, contract }}
                width={500}
                disabled={isUpdating}
              />
            </Suspense>
          </ErrorBoundary>
        </div>

        <div className="ptable">
          <div>
            <PerimetreTable
              initialPerimetre={initialPerimetre}
              perimetre={perimetre}
              removePees={togglePeeModal}
              disabled={isUpdating}
            />
          </div>

          <footer>
            <div>
              <Button
                color="secondary"
                onClick={toggleImportModal}
                disabled={typeof navigator.clipboard === 'undefined' || isUpdating}
              >
                <FaFileImport />
                <span>{t('global.import')}</span>
              </Button>

              <Button color="secondary" onClick={handleExport}>
                <FaFileExcel />
                <span>{t('global.export')}</span>
              </Button>
            </div>

            <div>
              <Button color="light" onClick={reset} disabled={isUpdating}>
                <FaRedo />
                <span>{t('global.reset')}</span>
              </Button>

              <Button
                color="secondary"
                onClick={handlesubmit}
                disabled={isUpdating || perimetre.length === 0}
              >
                {isUpdating ? <Spinner size="sm" /> : <FaCheck />}
                <span>{t('global.validate')}</span>
              </Button>
            </div>
          </footer>
        </div>
      </div>

      <Dialog isOpen={isImportModalOpen} close={closeImportModal} css={{ width: 500 }}>
        {isImportModalOpen && <LotPerimetreImport setData={addPees} close={closeImportModal} />}
      </Dialog>
    </>
  )
}

export const LotPerimetre = () => {
  return (
    <section className="rounded-sm shadow-sm bg-white p-4 position-relative h-100">
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Perimetre />
        </Suspense>
      </ErrorBoundary>
    </section>
  )
}

const TextFilter = ({ filter, onChange }: any) => (
  <input
    className="form-control form-control-sm m-1"
    value={filter?.value ?? ''}
    onChange={(e) => onChange(e.target.value)}
  />
)

const defaultFilterMethod = (filter: any, rows: any[]) =>
  matchSorter(rows, filter.value, {
    keys: [filter.id],
    threshold: matchSorter.rankings.CONTAINS,
  })
