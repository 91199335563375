import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import { loadUser } from 'redux-oidc'
import moment from 'moment'

import { accessTokenMiddleware, userManager, i18n, lanId, lngIsoStr } from 'commons'
import { rootReducer as reducer, RootState } from 'store'
import { persist } from 'persist'

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  accessTokenMiddleware,
]

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware,
  reducer,
})

persist(store)
loadUser(store, userManager)

// i18n
let prevLang: number | null = null
store.subscribe(() => {
  const state = store.getState()
  const lang = Number(lanId(state))
  if (lang !== prevLang) {
    prevLang = lang
    moment.locale(lang === 2 ? 'en' : 'fr')
    i18n.changeLanguage(lngIsoStr(lang))
  }
})

export default store

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
