import throttle from 'lodash/throttle'
import pick from 'lodash/pick'
import get from 'lodash/get'

const STATE_KEY_NAME = 'econtract-state'
const EID_KEY_NAME = 'econtract-eid'

const getEid = (state: any) => get(state, 'oidc.user.profile.eid', null)

const loadState = () => {
  try {
    const eid = localStorage.getItem(EID_KEY_NAME)
    const serializedState = localStorage.getItem(STATE_KEY_NAME)
    if (serializedState === null || eid === null) return null
    const state = JSON.parse(serializedState)
    return { eid, state }
  } catch (error) {
    console.log(error)
    return null
  }
}

const saveState = (eid: any, state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(EID_KEY_NAME, eid)
    localStorage.setItem(STATE_KEY_NAME, serializedState)
  } catch (err) {
    console.error(err)
  }
}

export const persist = (store: any) => {
  store.subscribe(
    throttle(() => {
      const state = store.getState()
      const eid = getEid(state)
      if (state.oidc.user && !state.oidc.user.expired) {
        saveState(eid, pick(state, ['selection']))
      }
    }, 1000),
  )

  const persisted = loadState()

  if (persisted !== null) {
    const { eid, state: persistedState } = persisted

    let unsubscribe: any = null

    const handleEid = () => {
      const isSameEid = getEid(store.getState()) === eid
      const isCallback = window.location.pathname === '/callback'
      if (isSameEid && !isCallback) {
        unsubscribe()
        store.dispatch({ type: 'econtract/LOAD_PRESISTED_STATE', persistedState })
      }
    }

    unsubscribe = store.subscribe(handleEid)
  }
}
