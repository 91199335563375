export { default as accessTokenMiddleware } from './middlewares/accessToken'
export { default as userManager } from './userManager'
export { default as i18n, lngIso } from './i18n'
export { superFetch } from './helpers/apiConf'
export * from './helpers/env'

export { userReducer } from './store/user'

export { SilentRedirectPage } from './components/SilentRedirectPage'
export { Callback } from './components/Callback'
export { AccessDenied } from './components/AccessDenied'
export { Logout } from './components/Logout'
export { Version } from './components/Version'
export { UserPanel } from './components/UserPanel'

export const lanId = (state: any) => {
  if (state.user && state.user.userInfo && state.user.userInfo.LanId)
    return state.user.userInfo.LanId
  if (state.oidc && state.oidc.user === null) return 1
  return (
    (state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.lan) ||
    state.oidc.user.profile.lan ||
    1
  )
}

export const lngIsoStr = (lanId: number) => {
  switch (lanId) {
    case 2:
      return 'en'
    case 1:
    default:
      return 'fr'
  }
}
