import React from 'react'
import { Formik } from 'formik'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'

import { useLot, useUpdateLot } from 'api'
import { LotForm } from './LotForm'
import { schema } from './lotSchema'
import { addNotification } from 'notification'

export const LotInfos: React.FC = () => {
  const { t } = useTranslation()
  const lot = useLot()
  const [mutate, { status, error }] = useUpdateLot()

  React.useEffect(() => {
    if (status === 'error') {
      addNotification({
        type: 'danger',
        title: t('lot.infos.updateErrorTitle'),
        message: String(error),
      })
    } else if (status === 'success') {
      addNotification({
        type: 'success',
        message: t('lot.infos.updateSuccess'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const submit = React.useCallback(
    async (values, actions) => {
      if (lot === null) return
      await mutate({ lot: values })
      actions.setSubmitting(false)
    },
    [lot, mutate],
  )

  if (lot === null) return null

  const values = pick(lot, [
    'Id',
    'Nom',
    'Type',
    'Debut',
    'Fin',
    'Horosaisonnier',
    'Tarif',
    'IdContrat',
  ])

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema(t)}
      onSubmit={submit}
    >
      {(props) => <LotForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
