import React from 'react'
import { useSelector } from 'react-redux'
import { FaInfoCircle, FaDollarSign } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'hooks'
import { getContractId, getLotId } from 'store/selection'
import { LotInfos } from 'components/sections/LotInfos'
import { LotTarif } from 'components/sections/LotTarif'
import { Section } from 'components/atoms'

export const LotPageInfos: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const contractId = useSelector(getContractId)
  const lotId = useSelector(getLotId)
  if (contractId === null || lotId === null) return null

  return (
    <div
      css={{
        display: 'grid',
        gridGap: theme.spacing,
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: `
        'infos formula'
      `,
      }}
    >
      <Section title={t('lot.infos.title')} Icon={FaInfoCircle} css={{ gridArea: 'infos' }}>
        <LotInfos />
      </Section>

      <Section title={t('lot.price.title')} Icon={FaDollarSign} css={{ gridArea: 'formula' }}>
        <LotTarif />
      </Section>
    </div>
  )
}
