import React, { FC, useEffect } from 'react'
import { apiUrl } from '../helpers/env'
import { version } from '../../../package.json'

export const Version: FC = () => {
  const [apiVersion, setApiVersion] = React.useState('-')

  useEffect(() => {
    const wsCall = async () => {
      try {
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        const url = `${apiUrl()}version`
        const resp = await fetch(url, { credentials: 'include', headers })
        const { version } = await resp.json()
        setApiVersion(version)
      } catch (error) {
        console.log(error)
      }
    }
    wsCall()
  }, [])

  return <span>{`${apiVersion} / v${version}`}</span>
}
