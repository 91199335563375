export const downloadFile = (filename, content, { type = 'text/plain' } = {}) => {
  // Excel hack - See https://stackoverflow.com/a/17879474
  const BOM = '\ufeff'
  const parts = type === 'text/csv' ? [BOM, content] : [content]
  const blob = new Blob(parts, { type })

  // IE11
  const isIE11 = navigator && navigator.msSaveOrOpenBlob
  if (isIE11) return navigator.msSaveOrOpenBlob(blob, filename)

  // Non-IE11
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const downloadCSV = (filename, content) =>
  downloadFile(filename, content, { type: 'text/csv' })
