import * as Yup from 'yup'
import { TFunction } from 'i18next'

export const schema = (t: TFunction) => () =>
  Yup.object().shape(
    {
      Nom: Yup.string().required(t('global.requiredField')),
      Type: Yup.number().required(t('global.requiredField')),
      Debut: Yup.date()
        .when('Fin', (Fin: any, schema: any) => Fin && schema.max(Fin, t('global.startDateError')))
        .required(t('global.requiredField')),
      Fin: Yup.date()
        .when(
          'Debut',
          (Debut: any, schema: any) => Debut && schema.min(Debut, t('global.endDateError')),
        )
        .required(t('global.requiredField')),
      Horosaisonnier: Yup.number(),
      Tarif: Yup.number().required(t('global.requiredField')),
    },
    [['Debut', 'Fin']],
  )
