import { useQuery, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getContractId } from 'store/selection'

export const useLots = () => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)

  const { data } = useQuery<LotList, AnyQueryKey>(
    ready && contractId !== null && ['lots', { contractId }],
    () =>
      superFetch({
        url: `contrats/${contractId}/lots?type=exclude-virtual`,
      }),
  )

  return data
}

export const usePeeLots = ({ peeId }: { peeId: number }) => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)

  const { data: lots } = useQuery<LotList, AnyQueryKey>(
    ready && contractId !== null && ['peeLots', { contractId, peeId }],
    () =>
      superFetch({
        url: `contrats/${contractId}/pees/${peeId}/lots`,
      }),
  )

  return !lots ? [] : lots.filter((lot) => !['Fictive', 'Fictif'].includes(lot.type))
}
