import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { darken, lighten, transparentize } from 'polished'
import { FaTrashAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { useStyles, useBoolean } from 'hooks'
import { useContract } from 'api'
import { Dialog, Button } from 'components/atoms'
import { ContractDeleteDialog } from './ContractDeleteDialog'

export const ContractLink: React.FC<{ id: number; done: () => void }> = ({ id, done }) => {
  const { t } = useTranslation()
  const path = `/contrat/${id}/informations`
  const match = useRouteMatch({ path: `/contrat/${id}/:x`, exact: true })
  const history = useHistory()
  const ctr = useContract()
  const [isModalOpen, toggleModal, closeModal] = useBoolean(false)

  const css = useStyles((theme: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: match ? darken(0.2, theme.secondary) : 'transparent',
    padding: 2,
    '.link': {
      flex: 1,
      padding: '0.4rem 0.8rem',
      fontSize: '0.875rem',
      textAlign: 'left',
      color: theme.gray300,
      '&:hover, &:focus': {
        color: '#fff',
        textDecoration: 'none',
      },
    },
    '.action': {
      fontSize: '0.8rem',
      padding: '0.2rem 0.3rem',
      margin: '0 0.4rem',
      opacity: 0,
      transition: 'opacity .2s ease',
    },
    '&:hover': {
      backgroundColor: match
        ? darken(0.2, theme.secondary)
        : transparentize(0.5, lighten(0.2, theme.blue)),
      '.action': {
        opacity: 1,
      },
    },
  }))

  if (!ctr) return null

  return (
    <>
      <div css={css}>
        <Button
          color="link"
          className="link"
          onClick={() => {
            done()
            history.push(path)
          }}
        >
          {ctr.FormattedName}
        </Button>

        <Button
          className="action"
          size="sm"
          color="danger"
          title={t('sidebar.deleteContract')}
          onClick={toggleModal}
        >
          <FaTrashAlt />
        </Button>
      </div>

      <Dialog isOpen={isModalOpen} close={closeModal} css={{ width: 500 }}>
        {isModalOpen && <ContractDeleteDialog />}
      </Dialog>
    </>
  )
}
