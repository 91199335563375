import React from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { AppLayout } from 'components/templates/AppLayout'
import { PageLayout } from 'components/templates/PageLayout'

import { HomePage } from 'components/templates/HomePage'
import { ContractPageInfos } from 'components/templates/ContractPageInfos'
import { ContractPagePerimetre } from 'components/templates/ContractPagePerimetre'
import { ContractPageOptions } from 'components/templates/ContractPageOptions'
import { LotPageInfos } from 'components/templates/LotPageInfos'
import { LotPagePerimetre } from 'components/templates/LotPagePerimetre'
import { LotPageOptions } from 'components/templates/LotPageOptions'

export const AppRoutes = () => {
  const history = useHistory()

  React.useEffect(() => {
    const pathname = localStorage.getItem('econtract-location')
    if (pathname === null) return
    localStorage.removeItem('econtract-location')
    history.push(pathname)
  }, [history])

  return (
    <AppLayout>
      <Switch>
        <Route path="/contrat/:contractId/lot/:lotId">
          <PageLayout>
            <Switch>
              <Route path="/contrat/:contractId/lot/:lotId/informations">
                <LotPageInfos />
              </Route>

              <Route path="/contrat/:contractId/lot/:lotId/perimetre">
                <LotPagePerimetre />
              </Route>

              <Route path="/contrat/:contractId/lot/:lotId/options">
                <LotPageOptions />
              </Route>

              <Redirect
                from="/contrat/:contractId/lot/:lotId"
                to="/contrat/:contractId/lot/:lotId/informations"
              />
            </Switch>
          </PageLayout>
        </Route>

        <Route path="/contrat/:contractId">
          <PageLayout>
            <Switch>
              <Route path="/contrat/:contractId/informations">
                <ContractPageInfos />
              </Route>

              <Route path="/contrat/:contractId/perimetre">
                <ContractPagePerimetre />
              </Route>

              <Route path="/contrat/:contractId/options">
                <ContractPageOptions />
              </Route>

              <Redirect from="/contrat/:contractId" to="/contrat/:contractId/informations" />
            </Switch>
          </PageLayout>
        </Route>

        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </AppLayout>
  )
}
