/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { cx } from '@emotion/css'
import matchSorter from 'match-sorter'
import { FixedSizeList as List } from 'react-window'
import { useTranslation } from 'react-i18next'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from '@reach/combobox'

import { useThrottle } from 'hooks/useThrottle'

interface Item {
  Id: number
  Nom: string
}

const itemSize = 32
const listHeight = itemSize * 8
const pageOffset = listHeight * 5
const minHeight = 0.1

export const Select: React.FC<{
  value: Item | null
  items: Item[]
  invalid: boolean
  disabled: boolean
  onChange: (id: number) => void
}> = ({ value, items, invalid, disabled, onChange }) => {
  const { t } = useTranslation()
  const [term, setTerm] = React.useState('')
  const throttledTerm = useThrottle(term, 400)

  React.useEffect(() => {
    if (term !== value?.Nom) setTerm('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const results = React.useMemo(() => {
    if (throttledTerm.trim() === '') return items
    return matchSorter(items, throttledTerm, { keys: ['Nom'] })
  }, [items, throttledTerm])

  const [scrollOffset, setScrollOffset] = React.useState(0)
  const outerListRef = React.useRef<HTMLDivElement>(null)
  const innerListRef = React.useRef<HTMLDivElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const maxHeight = innerListRef.current?.getBoundingClientRect().height || listHeight
    switch (e.keyCode) {
      case 33: // pageUp
        setScrollOffset(Math.max(minHeight, scrollOffset - pageOffset))
        return
      case 34: // pageDown
        setScrollOffset(Math.min(scrollOffset + pageOffset, maxHeight))
        return
      case 35: // home
        setScrollOffset(maxHeight)
        return
      case 36: // end
        setScrollOffset(minHeight)
        return
    }
  }

  React.useLayoutEffect(() => {
    outerListRef?.current?.scrollTo({
      left: 0,
      top: scrollOffset,
      behavior: 'auto',
    })
  })

  return (
    <Combobox
      openOnFocus
      onSelect={(selection) => {
        setTerm(selection)
        const item = items.find((item) => item.Nom === selection)
        if (item) {
          onChange(item.Id)
        }
      }}
    >
      <ComboboxInput
        value={term}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        selectOnClick
        placeholder={value?.Nom ?? ''}
        className={cx('form-control', { 'is-invalid': invalid })}
        disabled={disabled}
        css={{
          '&::placeholder': {
            color: '#495057',
          },
          '&:focus::placeholder': {
            color: 'transparent',
          },
        }}
      />

      {results && (
        <ComboboxPopover className="shadow">
          {results.length > 0 ? (
            <ComboboxList persistSelection>
              <List
                height={Math.min(listHeight, itemSize * results.length)}
                itemCount={results.length}
                itemSize={itemSize}
                width="100%"
                itemData={{ items: results }}
                outerRef={outerListRef}
                innerRef={innerListRef}
              >
                {({ data: { items }, index, style }) => {
                  const item = items[index]
                  return (
                    <ComboboxOption
                      value={item.Nom}
                      css={{
                        ...style,
                        fontSize: 12,
                        display: 'flex',
                        alignItems: 'center',
                        '&:not(:last-of-type)': {
                          borderBottom: '1px dotted #d9d9d9',
                        },
                        '>span': {
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        },
                        '[data-user-value]': {
                          fontWeight: 400,
                          background: '#fcf3cf',
                        },
                        '[data-suggested-value]': {
                          fontWeight: 400,
                        },
                      }}
                    >
                      <span title={item.Nom}>
                        <ComboboxOptionText />
                      </span>
                    </ComboboxOption>
                  )
                }}
              </List>
            </ComboboxList>
          ) : (
            <div css={{ fontSize: 12, padding: 8 }}>{t('global.noResult')}</div>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  )
}
