import { useEffect, useRef, useState } from 'react'

export function useThrottle(value: any, limit: number) {
  const [throttledValue, setThrottledValue] = useState(value)
  const lastRan = useRef(Date.now())

  useEffect(() => {
    const handler = window.setTimeout(() => {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value)
        lastRan.current = Date.now()
      }
    }, limit - (Date.now() - lastRan.current))

    return () => window.clearTimeout(handler)
  }, [value, limit])

  return throttledValue
}
