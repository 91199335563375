import React, { FC } from 'react'
import {
  BiX,
  BiRefresh,
  BiCheck
} from 'react-icons/bi'
import { useTranslation } from 'react-i18next'

const style = {
  width: '.9rem',
  height: '.9rem',
  marginRight: '0.4rem',
  verticalAlign: 'baseline',
}

type Props = { id: number }

export const ValidationStatusIcon: FC<any> = ({ id, ...props }) => {
    const { t } = useTranslation()

    if (id === 1) return <span style={{ fontSize: '0.8rem', color: 'gray'}}>(<BiRefresh color="gray" /> {t('contract.infos.labels.pending')})</span>
    if (id === 2) return <span style={{ fontSize: '0.8rem', color: 'red'}}>(<BiX color="red" /> {t('contract.infos.labels.error')})</span>
    if (id === 3) return <span style={{ fontSize: '0.8rem', color: 'green'}}>(<BiCheck color="green" /> {t('contract.infos.labels.valid')})</span>
    return null
}
