import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/atoms'
import { useTreeContext } from './TreeContext'

export const TreeButtons: React.FC = () => {
  const ctx = useTreeContext()
  const { t } = useTranslation()

  return (
    <div
      css={{ display: 'flex', margin: '1rem 0', '>*:not(:last-child)': { marginRight: '1rem' } }}
    >
      <Button size="sm" color="light" onClick={ctx.expandAll}>
        {t('global.unfold')}
      </Button>

      <Button size="sm" color="light" onClick={ctx.collapseAll}>
        {t('global.fold')}
      </Button>
    </div>
  )
}
