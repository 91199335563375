import { useQuery, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getContractId } from 'store/selection'

const ONE_DAY = 86400000

export const isUor = (node: TreeNode): node is Uor => (node as Uor).UorNom !== undefined

export const useOrganisation = () => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)

  const { data } = useQuery<Organisation, AnyQueryKey>(
    ready && contractId !== null && ['organisation', { contractId }],
    () => superFetch({ url: `organisation?contratId=${contractId}` }),
    { staleTime: ONE_DAY },
  )

  return data
}
