import { Dictionary } from 'lodash'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import pick from 'lodash/fp/pick'
import pipe from 'lodash/fp/pipe'
import keyBy from 'lodash/fp/keyBy'
import groupBy from 'lodash/fp/groupBy'
import mapValues from 'lodash/fp/mapValues'

import React from 'react'
import { Form, TabContent, TabPane, Nav, NavItem, NavLink, Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import moment from 'moment'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { addNotification } from 'notification'

import { dateFormat } from 'helpers/formatter'
import { useLotTarif, useUpdateLotTarif, useLot } from 'api'
import { TarifTable } from './TarifTable'

const useFormData = (t: TFunction, data?: Tarif) => {
  return React.useMemo(() => {
    const articlesBase = filter(data?.Tarif.Articles, { ArtPoste: 0 })
    const articlesHrs = filter(data?.Tarif.Articles, { ArtPoste: 1 })

    const prices: Dictionary<Dictionary<any>> = pipe(
      groupBy('PosteId'),
      mapValues(keyBy('ArticleId')),
    )(data?.Prix)

    const base = keyBy('ArticleId')(
      articlesBase.map((article) => {
        const price = prices['undefined']?.[article.Id] ?? null
        return {
          ...pick(['Libelle', 'ArtType', 'ValeursRef'])(article),
          ...pick(['Id', 'LotId'])(price),
          Valeur: price?.Valeur ?? '',
          ArticleId: article.Id,
          PosteId: null,
        }
      }),
    )

    const hrs = data?.PostesHrs ?? []
    const postesRef = hrs.length > 0 ? hrs : [{ Id: -1, Nom: '' }]

    const postes: Dictionary<any> = postesRef.map((poste) => ({
      ...poste,
      articles: keyBy('ArticleId')(
        articlesHrs.map((article) => {
          const price = prices[poste.Id === -1 ? 'undefined' : poste.Id]?.[article.Id] ?? null
          return {
            ...pick(['Libelle', 'ArtType', 'ValeursRef'])(article),
            ...pick(['Id', 'LotId'])(price),
            Valeur: price?.Valeur ?? '',
            ArticleId: article.Id,
            PosteId: poste.Id,
          }
        }),
      ),
    }))

    const itemSchema = (item: any) =>
      item.ArtType === 4
        ? Yup.string()
            .test('custom-date', `${dateFormat()}`, (value: any) =>
              moment(value, 'DD/MM/YYYY HH:mm:ss', true).isValid(),
            )
            .required(t('global.requiredField'))
        : Yup.string().required(t('global.requiredField'))

    const schema = Yup.object().shape({
      base: Yup.object().shape(
        mapValues((item) => Yup.object().shape({ Valeur: itemSchema(item) }))(base),
      ),
      postes: Yup.array().of(
        Yup.object().shape({
          articles: Yup.object().shape(
            mapValues((item) => Yup.object().shape({ Valeur: itemSchema(item) }))(
              postes[0].articles,
            ),
          ),
        }),
      ),
    })

    return { schema, values: { base, postes } }
  }, [data, t])
}

export const LotTarif: React.FC = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = React.useState(0)

  const lot = useLot()
  const showPerItemTitle = (lot?.Horosaisonnier ?? 0) !== 0

  const initialData = useLotTarif()
  const [data, setData] = React.useState(initialData)
  const { schema, values } = useFormData(t, data)

  const [update, { data: updatedData, status, error }] = useUpdateLotTarif()

  React.useEffect(() => {
    if (initialData) {
      setData(initialData)
      setActiveTab(0)
    }
  }, [initialData])

  React.useEffect(() => {
    if (updatedData) setData(updatedData)
  }, [updatedData])

  React.useEffect(() => {
    if (status === 'error') {
      addNotification({
        type: 'danger',
        title: 'Formule de prix',
        message: String(error),
      })
    } else if (status === 'success') {
      addNotification({
        type: 'success',
        title: 'Formule de prix',
        message: 'Modification effectuée',
      })
    }
  }, [error, status])

  const hasBase = Object.values(values.base).length > 0
  const hasPostes = Object.values(values.postes[0].articles).length > 0
  if (!hasBase && !hasPostes) return null

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        const { base, postes } = values
        const payload = {
          TarifId: data?.Tarif.Id as number,
          Prix: [
            ...Object.values(base),
            ...flatMap(postes, (poste) => Object.values(poste.articles)),
          ]
            .map(pick(['Id', 'LotId', 'ArticleId', 'Valeur', 'PosteId']))
            .map((prix: any) => {
              const isNumber = !isNaN(Number(prix.Valeur))
              const Valeur = isNumber ? prix.Valeur.toString().replace('.', ',') : prix.Valeur

              return {
                ...prix,
                Valeur,
                PosteId: prix.PosteId === -1 ? null : prix.PosteId,
              }
            }),
        }
        await update(payload)
        actions.setSubmitting(false)
      }}
    >
      {(props) => {
        const isLoading = status === 'loading' || props.isSubmitting
        const isDisabled = isLoading || Object.keys(props.touched).length === 0

        return (
          <Form
            onSubmit={props.handleSubmit}
            css={{
              '.nav': {
                userSelect: 'none',
              },
              '.h7': {
                fontWeight: 'bold',
                margin: '1.4rem 0 1rem 0',
                padding: '0 4px',
              },
              '.tab-content': {
                background: '#fff',
                border: '1px solid #dee2e6',
                borderTop: 'none',
                padding: '.8rem .6rem',
              },
              footer: {
                marginTop: '2rem',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                '>*': {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  button: { marginLeft: '0.6rem' },
                },
              },
            }}
          >
            {hasBase && (
              <>
                <div className="h7">{t('lot.price.generalSettings')}</div>
                <TarifTable
                  data={values.base}
                  getName={(ArticleId) => `base[${ArticleId}]Valeur`}
                />
              </>
            )}

            {hasPostes && (
              <>
                {showPerItemTitle && <div className="h7">{t('lot.price.perItemSettings')}</div>}

                {values.postes[0].Id === -1 ? (
                  <TarifTable
                    data={values.postes[0].articles}
                    getName={(ArticleId) => `postes[0].articles[${ArticleId}]Valeur`}
                  />
                ) : (
                  <>
                    <Nav tabs css={{ flexWrap: 'nowrap', alignItems: 'stretch' }}>
                      {values.postes.map((poste: any, i: number) => (
                        <NavItem key={i}>
                          <NavLink
                            className={`${activeTab === i ? 'active' : 'inactive'}`}
                            onClick={() => setActiveTab(i)}
                            css={{ height: '100%' }}
                          >
                            {poste.Nom}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      {values.postes.map((poste: any, i: number) => (
                        <TabPane tabId={i} key={poste.Id}>
                          <TarifTable
                            key={poste.Id}
                            data={Object.values(values.postes[i].articles)}
                            getName={(ArticleId) => `postes[${i}].articles.${ArticleId}.Valeur`}
                          />
                        </TabPane>
                      ))}
                    </TabContent>
                  </>
                )}
              </>
            )}

            <footer>
              <div>
                <Button color="light" disabled={isDisabled} onClick={() => props.resetForm()}>
                  {t('global.reset')}
                </Button>

                <Button type="submit" disabled={isDisabled}>
                  {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
                  <span>{t('global.validate')}</span>
                </Button>
              </div>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}
