import React from 'react'
import { useSelector } from 'react-redux'

import { getContractId, getLotId } from 'store/selection'
import { LotPerimetre } from 'components/sections/LotPerimetre'

export const LotPagePerimetre: React.FC = () => {
  const contractId = useSelector(getContractId)
  const lotId = useSelector(getLotId)
  if (!contractId || !lotId) return null

  return <LotPerimetre />
}
