import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Version } from 'commons'
import { getContractId, getLotId, setContractId, setLotId } from 'store/selection'
import { useStyles } from 'hooks'

const useParamsEffects = () => {
  const { contractId, lotId } = useParams<any>()

  const dispatch = useDispatch()
  const selectedContractId = useSelector(getContractId)
  const selectedLotId = useSelector(getLotId)

  React.useEffect(() => {
    if (!contractId || selectedContractId === Number(contractId)) return
    const id = Number(contractId)
    dispatch(setContractId(id))
  }, [dispatch, contractId, selectedContractId])

  React.useEffect(() => {
    if (!lotId || selectedLotId === Number(lotId)) return
    const id = Number(lotId)
    dispatch(setLotId(id))
  }, [dispatch, lotId, selectedLotId])
}

export const PageLayout: React.FC = ({ children }) => {
  useParamsEffects()

  const styles = useStyles((theme: any) => ({
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    main: {
      flex: 1,
      padding: theme.spacing * 2,
    },
    '.app-footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '.4rem .6rem',
      fontSize: '.8rem',
      color: theme.gray600,
    },
  }))

  return (
    <div css={styles}>
      <main>{children}</main>

      <footer className="app-footer">
        <Version />
      </footer>
    </div>
  )
}
