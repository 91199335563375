import React, { FC, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'hooks'
import { ReactComponent as EleneoLogo } from 'images/logo-eleneo.svg'
import { ReactComponent as GazelLogo } from 'images/gazel-logo.svg'
import { Loader, ErrorBoundary } from 'components/atoms'
import { Lots } from 'components/sections/Lots'
import { getContractId } from 'store/selection'
import { ContractLink } from 'components/sections/ContractLink'

interface Props {
  toggleSearchPanel: () => void
  closeSearchPanel: () => void
  isSearchPanelOpen: boolean
}

export const Sidebar: FC<Props> = ({ toggleSearchPanel, closeSearchPanel, isSearchPanelOpen }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const contractId = useSelector(getContractId)

  return (
    <nav
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        color: theme.gray300,
        '>*': {
          marginTop: '2rem',
          flex: 0,
        },
        header: {
          marginTop: '1rem',
          fontFamily: 'Merlo, Muli',
          fontWeight: 300,
          color: theme.gray200,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          span: {
            fontSize: 10,
          },
          a: {
            color: theme.gray200,
            marginRight: '2rem',
            '&:hover': {
              textDecoration: 'none',
              color: '#fff',
            },
            svg: {
              color: theme.gray200,
              fill: theme.gray600,
              width: 'calc(1.2rem * 3.6)',
              height: '1.2rem',
              marginLeft: '.5rem',
              marginRight: '.3rem',
              marginBottom: 3,
              verticalAlign: 'bottom',
            },
          },
        },
      }}
    >
      <header>
        <Link to="/" css={{ fontSize: '3.6rem' }}>
          eContract
        </Link>
        <span>
        by
        <a href="https://www.eleneo.fr">
          <EleneoLogo />
        </a>
        </span>
        <a href="https://gazelenergie.fr">
          <GazelLogo style={{
            width: '100%',
            height: 'auto'
          }}/>
        </a>
      </header>

      <Button onClick={toggleSearchPanel} color="primary" css={{ width: '80%' }}>
        {isSearchPanelOpen ? t('sidebar.closePanel') : t('sidebar.openPanel')}
      </Button>

      {contractId !== null && (
        <div
          css={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            '>*': {
              marginTop: '1rem',
              flex: 0,
              '&:last-child': {
                flex: 1,
              },
            },
            '.title': {
              marginLeft: '0.7rem',
              fontSize: '1.2rem',
            },
          }}
        >
          <ErrorBoundary className="m-2">
            <Suspense fallback={<Loader />}>
              <div className="title">{t('sidebar.contract')}</div>
              <ContractLink id={contractId} done={closeSearchPanel} />

              <div className="title">{t('sidebar.lots')}</div>
              <Lots done={closeSearchPanel} css={{ flex: 1 }} />
            </Suspense>
          </ErrorBoundary>
        </div>
      )}
    </nav>
  )
}
