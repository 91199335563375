import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getContractId, getLotId } from 'store/selection'
import { useStyles } from 'hooks'
import { Wrapper } from 'components/atoms'
import { LotOptions } from 'components/sections/LotOptions'
import { LotOptionTypes } from 'components/sections/LotOptionTypes'
import { LotAddOptionType } from 'components/sections/LotAddOptionType'

export const LotPageOptions: React.FC = () => {
  const { t } = useTranslation()
  const contractId = useSelector(getContractId)
  const lotId = useSelector(getLotId)
  const [typeId, setTypeId] = React.useState<number | null>(null)

  const [types, setTypes] = React.useState<OptionType[]>([])
  const addType = (type: OptionType) => setTypes([...types, type])
  const removeType = (type: OptionType) =>
    setTypes(types.filter((t) => t.OptTypeId !== type.OptTypeId))

  const css = useStyles((theme: any) => ({
    background: theme.gray100,
    border: `1px solid ${theme.gray400}`,
    minHeight: '100%',
    overflow: 'hidden',

    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: `230px 1fr`,

    aside: {
      display: 'grid',
      gridTemplateRows: '3rem 1fr minmax(60px, auto)',
      '>*:last-child': {
        borderTop: '1px solid #dee2e6',
      },
    },

    h6: {
      padding: '0 1rem',
      margin: 0,
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
    },
  }))

  if (!contractId || !lotId) return null

  const type = types.find((t) => t.OptTypeId === typeId)

  return (
    <section className="rounded-sm shadow-sm" css={css}>
      <aside className="shadow">
        <h6 className="shadow-sm">{t('lot.options.types')}</h6>

        <Wrapper>
          <LotOptionTypes {...{ typeId, setTypeId, types, setTypes, removeType }} />
        </Wrapper>

        <Wrapper>
          <LotAddOptionType {...{ types, addType }} />
        </Wrapper>
      </aside>

      <Wrapper css={{ margin: '1.5rem' }}>
        {type && <LotOptions type={type as OptionType} />}
      </Wrapper>
    </section>
  )
}
