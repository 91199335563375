import React, { FC, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { FaPlusCircle } from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useTheme, useBoolean } from 'hooks'
import { ErrorBoundary, Loader, Button, Dialog } from 'components/atoms'
import { ContractFilters } from './ContractFilters'
import { ContractList } from './ContractList'
import { getContractId } from 'store/selection'
import { ContractCreate } from 'components/sections/ContractInfos'

export const Contracts: FC<{
  done: () => void
}> = ({ done }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const history = useHistory()
  const { pathname } = useLocation()
  const contractId = useSelector(getContractId)
  const [isModalOpen, toggleModal, closeModal] = useBoolean(false)

  return (
    <>
      <div
        css={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          header: {
            padding: theme.spacing * 2,
          },
          h5: {
            marginBottom: theme.spacing,
          },
          footer: {
            padding: theme.spacing * 1.5,
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      >
        <ErrorBoundary className="m-2">
          <Suspense fallback={<Loader />}>
            <header className="border-bottom">
              <ContractFilters />
            </header>

            <div css={{ overflowY: 'auto', height: '100%' }}>
              <ContractList
                value={contractId}
                onChange={(id) => {
                  const match = pathname.match(/contrat\/\d+\/(.*)/)
                  const page =
                    match !== null && match[1] && !match[1].startsWith('lot')
                      ? match[1]
                      : 'informations'
                  history.push(`/contrat/${id}/${page}`)
                }}
              />
            </div>

            <footer className="border-top">
              <Button onClick={toggleModal} color="primary" Icon={<FaPlusCircle />}>
                {t('sidebar.createContract')}
              </Button>
            </footer>
          </Suspense>
        </ErrorBoundary>
      </div>

      <Dialog isOpen={isModalOpen} close={closeModal}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <h5 id="new-contract">{t('sidebar.newContract')}</h5>
            {isModalOpen && (
              <ContractCreate
                done={() => {
                  closeModal()
                  done()
                }}
              />
            )}
          </Suspense>
        </ErrorBoundary>
      </Dialog>
    </>
  )
}
