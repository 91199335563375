import { lanId } from 'commons'
import store from 'configureStore'

export const decimalFormat = (x: string | number, nbDec = 2) => {
  if (!x) return ''
  const input = typeof x === 'string' ? parseFloat(x) : x
  if (Number.isInteger(input)) return x.toString()
  const val = input
    .toFixed(nbDec)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',')
  return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
}

export const noDecimalFormat = (x: string | number) => {
  const input = typeof x === 'string' ? parseFloat(x) : x
  const val = input
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',')
  return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
}

export const strDecimalFormat = (x: string | number) => {
  const input = typeof x === 'string' ? parseFloat(x) : x
  const val = input
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',')
  return val !== 'NaN' && val !== null && val !== undefined ? val : x
}

export const dateFormat = () => {
  switch (lanId(store.getState())) {
    case 2:
      return 'YYYY-MM-DD'
    case 1:
    default:
      return 'DD/MM/YYYY'
  }
}

export const dateFormatMonths = () => {
  switch (lanId(store.getState())) {
    case 2:
      return 'YYYY-MM'
    case 1:
    default:
      return 'MM/YYYY'
  }
}
