import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import last from 'lodash/last'

import { useLots } from 'api'
import { getContractId } from 'store/selection'
import { EnergyIcon } from 'components/atoms'
import { LotLink } from './LotLink'

export const LotList: React.FC<{ done: () => void }> = ({ done }) => {
  const history = useHistory()
  const lastRoute = last(history.location.pathname.split('/')) || ''
  const lotRoute = ['options', 'perimetre'].includes(lastRoute) ? lastRoute : 'informations'

  const contractId = useSelector(getContractId)
  const lots = useLots()
  if (!lots) return null

  return (
    <div>
      {lots.map((lot) => (
        <LotLink
          key={lot.id}
          to={`/contrat/${contractId}/lot/${lot.id}/${lotRoute}`}
          done={done}
          lotId={lot.id}
        >
          <EnergyIcon id={lot.nrjId} css={{ position: 'relative', top: 2 }} />
          <span>{lot.nom}</span>
        </LotLink>
      ))}
    </div>
  )
}
