import React, { FC, Suspense } from 'react'

import { useTheme } from 'hooks'
import { ErrorBoundary, Loader } from 'components/atoms'

interface Props {
  className?: string
  Icon?: FC
  title?: string
  minHeight?: number
}

export const Section: FC<Props> = ({
  title,
  Icon,
  minHeight = 100,
  className,
  children,
  ...props
}) => {
  const theme = useTheme() as any

  return (
    <section
      css={{
        background: theme.gray100,
        padding: theme.spacing * 1.5,
        border: `1px solid ${theme.gray400}`,
        '>h6': {
          fontSize: '1.2rem',
          marginBottom: '2rem',
          display: 'flex',
          alignItems: 'flex-start',
          '>*:not(:last-child)': { marginRight: '.6rem' },
        },
      }}
      className={`rounded-sm shadow-sm ${className}`}
      {...props}
    >
      {title && (
        <h6>
          {Icon && <Icon />}
          <div>{title}</div>
        </h6>
      )}
      <div css={{ position: 'relative', height: 'calc(100% - 45px)', minHeight }}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </ErrorBoundary>
      </div>
    </section>
  )
}
