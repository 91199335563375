import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'

import { getReady } from 'store/api'
import { getContractId } from 'store/selection'

export const useContractPerimetre = () => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)

  const { data } = useQuery<Perimetre, AnyQueryKey>(
    ready && contractId !== null && ['contractPerimetre', { contractId }],
    () =>
      superFetch({
        url: `contrats/${contractId}/perimetre`,
      }),
  )

  return data ?? []
}

export const useUpdateContractPerimetre = () => {
  const contractId = useSelector(getContractId)

  return useMutation<
    Perimetre,
    {
      contractId: number
      perimetre: {
        PeeId: number
        DateEntree: string | null
        DateSortie: string | null
      }[]
    }
  >(
    ({ contractId, perimetre }) =>
      superFetch({
        url: `contrats/${contractId}/perimetre`,
        method: 'POST',
        body: perimetre,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['contractPerimetre', { contractId }])
      },
    },
  )
}
