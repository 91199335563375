import React from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'hooks'
import { TextField, DateField, SelectField } from 'components/form'
import { useReferentiel } from 'api'

interface Props extends FormikProps<Partial<Contract>> {
  isLoading: boolean
}

export const ContractForm: React.FC<Props> = ({ isLoading, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any

  const { ListePays, ListeEnergie, ListeDevise } =
    useReferentiel()

  const keys = Object.keys(props.values)

  const isDisabled = isLoading || props.isSubmitting || Object.keys(props.touched).length === 0
  const hasError = Object.keys(props.errors).length > 0

  return (
    <Form
      onSubmit={props.handleSubmit}
      css={{
        section: {
          display: 'grid',
          gridGap: theme.spacing,
          gridTemplateColumns: 'repeat(2, 1fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 2,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
            display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.6rem' },
          },
        },
      }}
    >
      <section>
        <TextField name="Nom" label={t('contract.infos.labels.nom')} />
        <TextField name="Complement" label={t('contract.infos.labels.complement')} />
        <SelectField name="Nrj" label={t('contract.infos.labels.nrj')} options={ListeEnergie} />
        <DateField name="DebutLivraison" label={t('contract.infos.labels.debut')} />
        <DateField name="FinLivraison" label={t('contract.infos.labels.fin')} />
        <DateField name="DateSignature" label={t('contract.infos.labels.dateSignature')} />
      </section>

      <footer>
        <div />
        <div>
          <Button color="light" onClick={() => props.resetForm()} disabled={isDisabled}>
            {t('global.reset')}
          </Button>

          <Button type="submit" disabled={isDisabled || hasError}>
            {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
            <span>{t('global.validate')}</span>
          </Button>
        </div>
      </footer>
    </Form>
  )
}
