import React from 'react'
import { FormFeedback, Input } from 'reactstrap'
import moment, { Moment } from 'moment'
import { useFormikContext, useField } from 'formik'
import DateTime from 'react-datetime'

import { dateFormat } from 'helpers/formatter'

export const DateField: React.FC<{
  name: string
}> = ({ name }) => {
  const { isSubmitting, validateForm } = useFormikContext()
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name)

  const [inputValue, setInputValue] = React.useState<string | null>(null)

  const format = dateFormat()

  React.useEffect(() => {
    const date = moment(value, 'DD/MM/YYYY HH:mm:ss', true)
    const dateValue = date.isValid() ? date.format(format) : ''
    setInputValue(dateValue)
  }, [format, value])

  if (inputValue === null) return null

  return (
    <DateTime
      initialViewMode="days"
      updateOnView="days"
      dateFormat={format}
      timeFormat={false}
      utc={true}
      closeOnSelect={true}
      value={inputValue}
      onChange={(date: Moment | string) => {
        if (typeof date === 'string') {
          setInputValue(date)
        } else {
          setInputValue(date.format(format))
          setValue(date.format('DD/MM/YYYY HH:mm:ss'))

          setTimeout(() => {
            setTouched(true)
            validateForm()
          }, 400)
        }
      }}
      renderInput={({ value, onChange, ...props }: any) => {
        return (
          <>
            <Input
              {...props}
              placeholder={format}
              value={value}
              onChange={(e) => {
                onChange(e.target.value)
              }}
              invalid={!!error && !!touched}
              disabled={isSubmitting}
              onBlur={() => {
                const m = moment(inputValue, format, true)
                const date = m.isValid() ? m.format('DD/MM/YYYY HH:mm:ss') : inputValue
                setValue(date)
                setTimeout(() => {
                  setTouched(true)
                  validateForm()
                }, 400)
              }}
            />
            {error && touched && <FormFeedback>{error}</FormFeedback>}
          </>
        )
      }}
    />
  )
}
