import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'

import { getReady } from 'store/api'
import { getContractId } from 'store/selection'

export const useContract = () => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)

  const { data } = useQuery<Contract, AnyQueryKey>(
    ready && contractId !== null && ['contract', { contractId }],
    () =>
      superFetch({
        url: `contrats/infos/${contractId}?gazelEnergie=true`,
      }),
  )

  return data
}

export const useUpdateContract = () => {
  const contractId = useSelector(getContractId)

  return useMutation<Partial<Contract>, { contractId: number; contract: Partial<Contract> }>(
    ({ contractId, contract }) =>
      superFetch({
        url: `contrats/infos/${contractId}`,
        method: 'PUT',
        body: contract,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['contract', { contractId }])
        queryCache.refetchQueries('contracts')
      },
    },
  )
}

export const useCreateContract = () => {
  return useMutation<Partial<Contract>, { contract: Partial<Contract> }>(
    ({ contract }) =>
      superFetch({
        url: `contrats/infos`,
        method: 'POST',
        body: contract,
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries('contracts')
      },
    },
  )
}

export const useDeleteContract = () => {
  return useMutation<void, { contractId: number }>(
    ({ contractId }) =>
      superFetch({
        url: `contrats/infos/${contractId}`,
        method: 'DELETE',
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries('contracts')
      },
    },
  )
}

export const useUpdateContractValidationStatus = () => {
  const contractId = useSelector(getContractId)

  return useMutation<Partial<ContractValidationStatus>, { contractId: number; contract: Partial<ContractValidationStatus> }>(
    ({ contractId, contract }) =>
      superFetch({
        url: `contrats/infos/${contractId}/validationStatus`,
        method: 'PUT',
        body: contract,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['contract', { contractId }])
        queryCache.refetchQueries('contracts')
      },
    },
  )
}
