import React from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCreateLot, useReferentiel, useContract } from 'api'
import { LotForm } from './LotForm'
import { schema } from './lotSchema'
import { addNotification } from 'notification'

export const LotCreate: React.FC<{
  done?: () => void
}> = ({ done = () => {} }) => {
  const { t } = useTranslation()
  const contract = useContract() as Contract
  const [mutate, { data, status }] = useCreateLot()
  const { ListeTypeLot, ListeHorosaisonnier, ListeFormulePrix } = useReferentiel()
  const history = useHistory()

  React.useEffect(() => {
    if (!data) return
    history.push(`/contrat/${contract.Id}/lot/${data.Id}/informations`)
    setTimeout(done)
    addNotification({
      type: 'success',
      message: t('lot.infos.createSuccess', { name: data.Nom }),
    })
  }, [contract, data, done, history, t])

  const submit = React.useCallback(
    async (values, actions) => {
      try {
        await mutate({
          lot: values,
        })
        actions.setSubmitting(false)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('lot.infos.createErrorTitle'),
          message: (error as any).toString(),
        })
      }
    },
    [mutate, t],
  )

  const values: Partial<Lot> = {
    Nom: '',
    Type: ListeTypeLot[0].value,
    Debut: contract.DebutLivraison,
    Fin: contract.FinLivraison,
    Horosaisonnier: ListeHorosaisonnier[0].value,
    Tarif: ListeFormulePrix[0].value,
    IdContrat: contract.Id,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema(t)}
      onSubmit={submit}
    >
      {(props) => <LotForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
