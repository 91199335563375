import React, { FC } from 'react'

import { ReactComponent as Gas } from 'images/gas.svg'
import { ReactComponent as Elec } from 'images/elec.svg'

const style = {
  width: '.9rem',
  height: '.9rem',
  marginRight: '0.4rem',
  verticalAlign: 'baseline',
}

type Props = { id: number }

export const EnergyIcon: FC<any> = ({ id, ...props }) => {
  if (id === 1) return <Gas style={style} {...props} />
  if (id === 2) return <Elec style={style} {...props} />
  return null
}
