import React from 'react'
import { FaInfoCircle, FaSitemap } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getContractId } from 'store/selection'
import { useStyles } from 'hooks'
import { RouterTabs } from 'components/atoms'

export const ContractTabs = () => {
  const { t } = useTranslation()
  const contractId = useSelector(getContractId)

  const styles = useStyles((theme: any) => ({
    display: 'flex',
    alignItems: 'center',
    svg: { marginRight: '0.6rem' },
  }))

  const links = React.useMemo(
    () => [
      {
        path: `/contrat/${contractId}/informations`,
        label: (
          <span css={styles}>
            <FaInfoCircle />
            {t('tabs.infos')}
          </span>
        ),
      },
      {
        path: `/contrat/${contractId}/perimetre`,
        label: (
          <span css={styles}>
            <FaSitemap />
            {t('tabs.scope')}
          </span>
        ),
      },
      // {
      //   path: `/contrat/${contractId}/options`,
      //   label: (
      //     <span css={styles}>
      //       <MdSettings />
      //       {t('tabs.options')}
      //     </span>
      //   ),
      // },
    ],
    [contractId, styles, t],
  )

  return <RouterTabs links={links} />
}
