import React from 'react'
import { useTable, useResizeColumns, useFlexLayout, useSortBy } from 'react-table'
import { FaSortDown, FaSortUp } from 'react-icons/fa'
import { useRect } from '@reach/rect'

import { useStyles } from 'hooks'

const headerProps = (props: any, { column }: any) => getStyles(props, column.align)

const cellProps = (props: any, { cell }: any) => getStyles(props, cell.column.align)

const getStyles = (props: any, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'center' ? 'center' : align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]

const useScroll = () => {
  const ref1 = React.useRef<any>()
  const ref2 = React.useRef<any>()
  const rect1 = useRect(ref1)
  const rect2 = useRect(ref2)
  const hasScroll = rect1 && rect2 && rect2.height > rect1.height
  return { ref1, ref2, hasScroll }
}

export const Table: React.FC<{
  columns: any[]
  data: object[]
  sortBy?: any[]
  maxHeight?: number | string
  showHeader?: boolean
}> = ({ columns, data, maxHeight = 'unset', sortBy = [], showHeader = true, ...props }) => {
  const { ref1, ref2, hasScroll } = useScroll()
  const scrollWidth = hasScroll ? 10 : 0

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      } as any,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
  )

  const border = `1px solid #ced4da`

  const styles = useStyles((theme: any) => ({
    display: 'block',
    overflow: 'auto',
    borderSpacing: 0,
    border,
    '.thead': {
      fontWeight: '700',
      borderBottom: border,
      '.tr': {
        paddingRight: scrollWidth,
      },
      '.sort-icon': {
        verticalAlign: 'sub',
      },
    },
    '.tbody': {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: maxHeight,
      backgroundColor: theme.gray200,
    },
    '.tr': {
      height: 35,
      borderBottom: border,
      backgroundColor: '#fff',
      ':last-child': {
        borderBottom: 0,
      },
    },
    '.th, .td': {
      margin: 0,
      padding: '0.5rem',
      borderRight: border,
      position: 'relative',
      alignItems: 'center !important',
      ':last-child': {
        borderRight: 0,
      },
      '.resizer': {
        right: -5,
        width: 10,
        height: '100%',
        position: 'absolute',
        top: 0,
        zIndex: 500,
        touchAction: 'none',
      },
    },
    '.th': {
      svg: {
        position: 'relative',
        top: -3,
        marginLeft: 4,
      },
      '&:last-of-type': {
        '.resizer': {
          right: -scrollWidth,
        },
      },
    },
  }))

  return (
    <div {...getTableProps()} css={styles} {...props}>
      {showHeader && (
        <div className="thead">
          {headerGroups.map((headerGroup: any) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column: any) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.canResize &&
                      column.isSorted &&
                      (column.isSortedDesc ? (
                        <FaSortDown className="sort-icon" />
                      ) : (
                        <FaSortUp className="sort-icon" />
                      ))}
                  </div>
                  {column.canResize && <div {...column.getResizerProps()} className="resizer" />}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <div {...getTableBodyProps()} className="tbody" ref={ref1}>
        <div ref={ref2}>
          {rows.map((row: any) => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell: any) => (
                  <div {...cell.getCellProps(cellProps)} className="td">
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
