import React from 'react'
import { FormFeedback, Input } from 'reactstrap'
import { useFormikContext, useField } from 'formik'

export const TextField: React.FC<{
  name: string
  type: 'number' | 'text'
}> = ({ name, type }) => {
  const { isSubmitting } = useFormikContext()
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name)

  const handleChange = React.useCallback(
    (e) => {
      setTouched(true)
      const value = e.target.value
      setValue(type === 'text' ? value : value.toString().replace('.', ','))
    },
    [setTouched, setValue, type],
  )

  return (
    <div>
      <Input
        type={type}
        value={
          typeof value === 'undefined' ? '' : type === 'text' ? value : value.replace(',', '.')
        }
        invalid={!!error && !!touched}
        disabled={isSubmitting}
        onChange={handleChange}
        onBlur={() => setTouched(true)}
      />
      {error && touched && <FormFeedback>{error}</FormFeedback>}
    </div>
  )
}
