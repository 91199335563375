import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'

import { useLots } from 'api'
import { getLotId } from 'store/selection'

export const useLotsById = () => {
  const lots = useLots()
  return useMemo(() => {
    return keyBy(lots, 'id')
  }, [lots])
}

export const useCurrentLot = () => {
  const lots = useLotsById()
  const id = useSelector(getLotId)
  if (id === null) return null
  return lots?.[id] ?? null
}
