import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { getContractId } from 'store/selection'
import { useContract, useUpdateContract } from 'api'
import { ContractForm } from './ContractForm'
import { schema } from './contractSchema'
import { addNotification } from 'notification'

export const ContractInfos: React.FC = () => {
  const { t } = useTranslation()
  const contractId = useSelector(getContractId)
  const contract = useContract()
  const [mutate, { status }] = useUpdateContract()

  const supplierGazel = 2278
  const contratType = 1

  const values :Partial<Contract> = {
    NomLong: contract?.NomLong,
    NomCourt: contract?.NomCourt,
    Nom: contract?.Nom,
    Complement: contract?.Complement,
    Type: contratType,
    Nrj: contract?.Nrj,
    DebutLivraison: contract?.DebutLivraison,
    FinLivraison: contract?.FinLivraison,
    PaysId: contract?.PaysId,
    Devise: contract?.Devise,
    FournisseurId: supplierGazel,
    DateSignature: contract?.DateSignature,
    DelaiReglement: contract?.DelaiReglement,
    CompteFacturation: contract?.CompteFacturation
  }

  const submit = React.useCallback(
    async (values, actions) => {
      if (contractId === null) return
      try {
        await mutate({
          contractId,
          contract: values,
        })
        actions.setSubmitting(false)
        addNotification({
          type: 'success',
          message: t('contract.infos.updateSuccess', { name: values.NomCourt }),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.infos.updateErrorTitle'),
          message: (error as any).toString(),
        })
      }
    },
    [contractId, mutate, t],
  )

  if (!contract) return null

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema(t)}
      onSubmit={submit}
    >
      {(props) => <ContractForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
