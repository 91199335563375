import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'

import { getReady } from 'store/api'
import { getContractId } from 'store/selection'

export const useContractDocs = () => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)

  const { data } = useQuery<Document[], AnyQueryKey>(
    ready && contractId !== null && ['docs', { contractId }],
    () =>
      superFetch({
        url: `contrats/${contractId}/docs`,
      }),
  )

  return data
}

export const useUploadDoc = () => {
  const contractId = useSelector(getContractId)
  return useMutation<any, { formData: FormData }>(
    ({ formData }) =>
      superFetch({
        url: `contrats/${contractId}/docs`,
        method: 'POST',
        body: formData,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['docs', { contractId }])
      },
    },
  )
}

export const useDeleteDoc = () => {
  const contractId = useSelector(getContractId)
  return useMutation<{}, { docId: number }>(
    ({ docId }) =>
      superFetch({
        url: `contrats/${contractId}/docs/${docId}`,
        method: 'DELETE',
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['docs', { contractId }])
      },
    },
  )
}

export const useDownloadDoc = () => {
  const contractId = useSelector(getContractId)
  return useMutation<any, { docId: number }>(
    ({ docId }) =>
      superFetch({
        url: `contrats/${contractId}/docs/${docId}`,
        parse: false,
        responseType: 'blob',
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
    },
  )
}
