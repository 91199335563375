import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from 'hooks'
import {  Button } from 'reactstrap'
import { useCurrentContract, useBoolean } from 'hooks'
import { superFetch } from 'commons'
import { addNotification } from '../../notification'

export const ContractValorisation: React.FC = () => {
  const { t } = useTranslation()
  const ctr = useCurrentContract()
  const styles = useStyles((theme: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '>*:not(:last-child)': {
      marginBottom: theme.spacing * 2,
    },
  }))
  const callContractsValorisation = (contractIds:any) => {
    return superFetch({
      url: 'valorisations',
      method: 'POST',
      body: [Number(contractIds)],
    })
  }

  const handleClick =  React.useCallback(() => {
    const fetchData = async () => {
        const result = window.confirm(t('contract.valorisation.confirmationMessage'));
        if(result){
            try {
            await callContractsValorisation(
              ctr?.id
            )
            addNotification({
              type: 'success',
              title:"",
              message: t('contract.valorisation.successMessage'),
            })
          }
          catch (error) {
            addNotification({
              type: 'danger',
              title:"",
              message: t('contract.valorisation.FailedMessage'),
            })
            
        }
        }
        
       };
    fetchData();
  }, []);
  

  
  return (
    <div css={styles}>
     <Button
        type="submit"
        css={{ display: 'flex', alignItems: 'center' }}
        onClick={handleClick}
      > {t('contract.valorisation.buttonTitle')}</Button>
     
    </div>
  )
}
