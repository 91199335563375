import React from 'react'
import { Nav, NavItem, NavLink, Button } from 'reactstrap'

import { useLotOptionTypes } from 'api'
import { useStyles } from 'hooks'

export const LotOptionTypes: React.FC<{
  typeId: number | null
  setTypeId: (typeId: number | null) => void
  types: OptionType[]
  setTypes: (types: OptionType[]) => void
  removeType: (type: OptionType) => void
}> = ({ typeId, setTypeId, types, setTypes, removeType }) => {
  const originalTypes = useLotOptionTypes()

  // Initialize types
  React.useEffect(() => {
    if (originalTypes) setTypes(originalTypes)
  }, [originalTypes, setTypes])

  // Select first type
  React.useEffect(() => {
    if (!types) return
    if (types.length === 0) {
      setTypeId(null)
    } else {
      const found = types.find((type) => type.OptTypeId === typeId)
      if (found) return
      setTypeId(types[0].OptTypeId)
    }
  }, [setTypeId, typeId, types])

  const css = useStyles((theme: any) => ({
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    position: 'absolute',
    '.nav-item': {
      position: 'relative',
      '.nav-link': {
        userSelect: 'none',
        paddingRight: 30,
        borderRadius: 0,
        transition: 'background-color .2s ease',
        '&:hover': {
          backgroundColor: theme.gray200,
        },
        '&.active': {
          backgroundColor: theme.gray300,
          color: 'inherit',
        },
        '&:not(.active)': {
          cursor: 'pointer',
        },
      },
      button: {
        position: 'absolute',
        top: '50%',
        right: 8,
        padding: 5,
        transform: 'translateY(-50%)',
        opacity: 0,
        transition: 'opacity .2s ease',
      },
      '&:hover': {
        button: {
          opacity: 0.5,
        },
      },
      '&:not(:last-child)': {
        '.nav-link': {
          borderBottom: `1px solid ${theme.gray300}`,
        },
      },
    },
  }))

  if (!types) return null

  return (
    <Nav vertical pills className="flex-nowrap" css={css}>
      {types.map((type) => (
        <NavItem key={type.OptTypeId}>
          <NavLink active={type.OptTypeId === typeId} onClick={() => setTypeId(type.OptTypeId)}>
            {type.Nom}
          </NavLink>

          {!originalTypes?.some((t) => t.OptTypeId === type.OptTypeId) && (
            <Button size="sm" onClick={() => removeType(type)} close />
          )}
        </NavItem>
      ))}
    </Nav>
  )
}
