import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Input, Button, Spinner } from 'reactstrap'
import { queryCache } from 'react-query'
import { Trans, useTranslation } from 'react-i18next'

import { useDeleteContract, useContract } from 'api'
import { getContractId, getLotId, setContractId } from 'store/selection'
import { addNotification } from 'notification'
import { useStyles } from 'hooks'

export const ContractDeleteDialog: React.FC = () => {
  const { t } = useTranslation()
  const [mutate, { status }] = useDeleteContract()
  const history = useHistory()
  const dispatch = useDispatch()
  const contractId = useSelector(getContractId)
  const lotId = useSelector(getLotId)
  const contract = useContract()
  const [input, setInput] = React.useState<string>('')

  const contractName = contract && contract.NomCourt
  const match = input === contractName

  const handleClick = React.useCallback(async () => {
    if (contractId === null) return null
    try {
      await mutate({ contractId })

      await Promise.all([
        queryCache.setQueryData(['contract', { contractId }], null),
        queryCache.setQueryData(['lot', { contractId, lotId }], null),
        queryCache.setQueryData(['lots', { contractId }], null),
      ])

      history.push('/')
      dispatch(setContractId(null))

      addNotification({
        type: 'success',
        message: t('contract.delete.success', { name: contractName }),
      })
    } catch (error) {
      addNotification({
        type: 'danger',
        title: t('contract.delete.errorTitle'),
        message: (error as any).toString(),
      })
    }
  }, [contractId, contractName, dispatch, history, lotId, mutate, t])

  const styles = useStyles((theme: any) => ({
    '>*:not(:last-child)': {
      marginBottom: '1.5rem',
    },
  }))

  if (contractId === null || contract === null) return null

  return (
    <div css={styles}>
      <p>
        {t('contract.delete.p1')}
        <br />
        {t('contract.delete.p2')}
        <br />
        <Trans i18nKey="contract.delete.warning">
          Cela supprimera définitivement le contrat{' '}
          <strong style={{ whiteSpace: 'nowrap' }}>{{ name: contractName }}</strong>
        </Trans>
      </p>

      <p>
        <Trans i18nKey="contract.delete.confirm">
          Veuillez taper <strong style={{ whiteSpace: 'nowrap' }}>{{ name: contractName }}</strong>{' '}
          pour confirmer
        </Trans>
      </p>

      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled={status === 'loading'}
      />

      <Button
        color={match ? 'danger' : 'light'}
        disabled={!match || status === 'loading'}
        onClick={handleClick}
        css={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {status === 'loading' ? (
          <>
            <Spinner size="sm" style={{ marginRight: '.6rem' }} />
            <span>{t('contract.delete.deleting')}</span>
          </>
        ) : (
          <span>{t('contract.delete.submit')}</span>
        )}
      </Button>
    </div>
  )
}
