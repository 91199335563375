import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Button } from 'reactstrap'
import { FaSave } from 'react-icons/fa'
import { store } from 'react-notifications-component'

import { changePassword, passwordSuccess, passwordError } from '../../store/user'
import { TextInput } from './TextInput'

const notification = {
  title: 'Password',
  insert: 'top',
  container: 'bottom-left',
  animationOut: ['fadeOut'],
  dismiss: {
    duration: 3000,
    showIcon: true,
  },
}

const Security: FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const changePasswordSuccess = useSelector(passwordSuccess)
  const changePasswordError = useSelector(passwordError)

  const [currentPassword, updatePassword] = useState('')
  const [newPassword, updateNewPassword] = useState('')
  const [newPasswordConfirmation, updateNewPasswordConfirmation] = useState('')

  useEffect(() => {
    if (changePasswordSuccess) {
      store.addNotification({
        ...notification,
        type: 'success',
        message: 'Your password has been changed successfully',
      })
    }
  }, [changePasswordSuccess])

  useEffect(() => {
    if (changePasswordError) {
      store.addNotification({
        ...notification,
        type: 'danger',
        message: changePasswordError,
      })
    }
  }, [changePasswordError])

  return (
    <Form
      css={{
        height: '100%',
        section: {
          height: 'calc(100% - 140px)',
          overflowY: 'auto',
          padding: 30,
        },
        footer: {
          padding: '0 15px',
          height: 70,
          borderTop: '1px solid #adb5bd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '>*:not(:last-child)': { marginRight: '0.6rem' },
        },
      }}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(
          changePassword({
            Password: currentPassword,
            NewPassword: newPassword,
            NewPasswordConfirm: newPasswordConfirmation,
          }),
        )
        done()
      }}
    >
      <section>
        <TextInput
          id="currentPassword"
          value={currentPassword}
          onChange={(e) => updatePassword(e.target.value)}
          label={t('user.security.currentPassword')}
          type="password"
          required
        />

        <TextInput
          id="newPassword"
          value={newPassword}
          onChange={(e) => updateNewPassword(e.target.value)}
          label={t('user.security.newPassword')}
          type="password"
          required
        />

        <TextInput
          id="newPasswordConfirm"
          value={newPasswordConfirmation}
          onChange={(e) => updateNewPasswordConfirmation(e.target.value)}
          label={t('user.security.newPasswordConfirmation')}
          type="password"
          required
        />
      </section>

      <footer>
        <Button type="button" color="secondary" onClick={done}>
          {t('user.userInfo.cancel')}
        </Button>

        <Button
          type="submit"
          color="primary"
          disabled={currentPassword === '' || newPassword === '' || newPasswordConfirmation === ''}
        >
          <FaSave css={{ marginRight: '0.6rem' }} />
          {t('user.userInfo.save')}
        </Button>
      </footer>
    </Form>
  )
}

export default Security
