import React, { FC, useState, useCallback } from 'react'
import { FaFileImport, FaPaste } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { usePaste, useStyles } from 'hooks'
import { Button } from 'components/atoms'
import { PasteTable } from './PasteTable'

export const ImportDialog: FC<{
  close: () => void
  setData: (data: any) => void
  isValidCell: (value: string) => boolean
  columns: any[]
}> = ({ close, setData, isValidCell, columns }) => {
  const { t } = useTranslation()

  const parseClipboard = useCallback(
    (s: string) => {
      const columnIds = columns.map((c) => c.id)
      return s
        .trim()
        .split('\r\n')
        .map((line) => line.split('\t'))
        .map((row) =>
          row.reduce(
            (acc, cell, i) =>
              !columnIds[i]
                ? acc
                : {
                    ...acc,
                    [columnIds[i]]: cell,
                  },
            {},
          ),
        )
    },
    [columns],
  )

  const [tableData, setTableData] = useState<any>([])

  const setTableDataFromClipboard = useCallback(
    (s: string) => setTableData(parseClipboard(s)),
    [parseClipboard],
  )

  usePaste(setTableDataFromClipboard)

  const pasteClipboard = useCallback(() => {
    navigator.clipboard
      .readText()
      .then(setTableDataFromClipboard)
      .catch((error) => console.log(error))
  }, [setTableDataFromClipboard])

  const cancel = useCallback(() => {
    setTableData([])
    close()
  }, [close])

  const upload = useCallback(() => {
    setData(tableData)
    setTableData([])
    close()
  }, [setData, tableData, close])

  const styles = useStyles(() => ({
    '>div': {
      backgroundColor: '#fff',
      height: '60vh',
      overflowY: 'auto',
      marginBottom: '1.5rem',
      borderBottom: '1px solid #dee2e6',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
    },
  }))

  return (
    <div css={styles}>
      <div>
        <PasteTable
          data={tableData}
          columns={columns}
          isValidCell={isValidCell}
          setData={setTableData}
        />
      </div>

      <footer>
        <div css={{ display: 'flex', '>*:not(:last-child)': { marginRight: '.6rem' } }}>
          <Button Icon={<FaPaste />} onClick={pasteClipboard}>
            {t('global.paste')}
          </Button>
        </div>

        <div css={{ display: 'flex', '>*:not(:last-child)': { marginRight: '.6rem' } }}>
          <Button color="secondary" onClick={cancel}>
            {t('global.cancel')}
          </Button>

          <Button Icon={<FaFileImport />} onClick={upload} disabled={tableData.length === 0}>
            {t('global.import')}
          </Button>
        </div>
      </footer>
    </div>
  )
}
