import React from 'react'
import { FaRedo } from 'react-icons/fa'
import { Button } from 'reactstrap'
import { Translation } from 'react-i18next'

interface Props {
  message?: string
  className?: string
}

interface State {
  error: Error | null
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
  }

  tryAgain = () => this.setState({ error: null })

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // console.log(error)
    // console.log(info)
  }

  render() {
    const { message } = this.props
    const { error } = this.state
    if (!error) return this.props.children

    return (
      <Translation>
        {(t) => (
          <div className={`text-danger ${this.props.className}`}>
            {message || t('global.error')}

            <Button className="m-2" color="danger" size="sm" onClick={this.tryAgain}>
              <FaRedo style={{ marginRight: '0.6rem' }} />
              {t('global.retry')}
            </Button>
          </div>
        )}
      </Translation>
    )
  }
}
