import v4 from 'uuid/v4'
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'

import i18n, { lngIso } from '../../i18n'
import types from './types'
import * as api from './api'

const getPlatform = (state: any) => state.oidc.user.profile.pla

type ThunkResult<R> = ThunkAction<R, object, undefined, AnyAction>

export const logout: (u: any) => ThunkResult<any> = (connectedUser) => (dispatch) => {
  dispatch({
    type: types.USER_LOGOUT_REQUEST,
  })

  return api.logout(connectedUser.id_token)
}

export const fetchUserInfo: () => ThunkResult<any> = () => async (dispatch, getState) => {
  dispatch({
    type: types.USER_INFO_REQUEST,
  })

  const platform = getPlatform(getState())

  try {
    const user = await api.userInfos(platform)
    dispatch({
      type: types.USER_INFO_SUCCESS,
      user,
    })
  } catch (error) {
    dispatch({
      type: types.USER_INFO_FAILURE,
      message: (error as any).Message || 'Something went wrong.',
    })
  }
}

export const postUserForm: (u: any) => ThunkResult<any> = (usr) => async (dispatch, getState) => {
  dispatch({
    type: types.USER_FORM_POST_REQUEST,
  })

  const platform = getPlatform(getState())

  try {
    const user = await api.postUser(usr, platform)
    dispatch({
      type: types.USER_FORM_POST_SUCCESS,
      user,
    })
    i18n.changeLanguage(lngIso(user.LanId))
    document.body.classList.remove('no-scroll')
  } catch (error) {
    dispatch({
      type: types.USER_FORM_POST_FAILURE,
      message: (error as any).Message || 'Something went wrong.',
    })
  }
}

export const changePassword: (p: any) => ThunkResult<any> = (passwordChange) => async (
  dispatch,
  getState,
) => {
  const uuid = v4()
  dispatch({
    type: types.CHANGE_PASSWORD_REQUEST,
    uuid,
  })

  const platform = getPlatform(getState())
  try {
    await api.changePassword(uuid, passwordChange, platform)
    dispatch({
      type: types.CHANGE_PASSWORD_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: types.CHANGE_PASSWORD_FAILURE,
      message: (error as any).Message || 'Something went wrong.',
    })
  }
}
