import React, { FC, useMemo, useEffect } from 'react'
import { Label, Input } from 'reactstrap'
import { IoIosArrowRoundForward as Arrow } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'hooks'

const currentYear = new Date().getFullYear()

interface Props {
  start: number | null
  end: number | null
  setStart: (year: number) => void
  setEnd: (year: number) => void
  size?: 'sm' | 'lg'
}

export const Period: FC<Props> = ({ start, end, setStart, setEnd, size, ...props }) => {
  const theme = useTheme() as any
  const { t } = useTranslation()

  const years: number[] = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => currentYear - 5 + i)
  }, [])

  useEffect(() => {
    if (start === null) setStart(currentYear)
    if (end === null) setEnd(currentYear + 2)
  }, [end, setEnd, setStart, start])

  return (
    <div {...props}>
      <Label>{t('global.period')}</Label>

      <div
        css={{
          display: 'grid',
          gridGap: theme.spacing,
          gridTemplateColumns: '100px 20px 100px',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <Input
          type="select"
          bsSize={size}
          value={start || ''}
          onChange={(e) => setStart(Number(e.target.value))}
        >
          {years.map((year) => (
            <option key={year} value={year} disabled={end === null || year > end}>
              {year}
            </option>
          ))}
        </Input>

        <Arrow size="2rem" />

        <Input
          type="select"
          bsSize={size}
          value={end || ''}
          onChange={(e) => setEnd(Number(e.target.value))}
        >
          {years.map((year) => (
            <option key={year} value={year} disabled={start === null || year < start}>
              {year}
            </option>
          ))}
        </Input>
      </div>
    </div>
  )
}
