import React from 'react'
import { Form, Label, Button, CustomInput, Spinner, FormGroup } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useUploadDoc } from 'api'
import { addNotification } from 'notification'
import { useStyles } from 'hooks'

export const DocsForm = () => {
  const { t } = useTranslation()
  const [uploadDoc, { status }] = useUploadDoc()
  const [touched, setTouched] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault()
      if (inputRef.current === null) return

      const { files } = inputRef.current
      if (files === null || files.length === 0) return

      const file = files[0]
      const formData = new FormData()
      formData.append('file', file, file.name)
      try {
        await uploadDoc({ formData })
        addNotification({
          type: 'success',
          title: `${file.name}`,
          message: t('contract.docs.addSuccess'),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: `${file.name}`,
          message: `${error}`,
        })
      }
    },
    [t, uploadDoc],
  )

  const styles = useStyles(() => ({
    display: 'flex',
    alignItems: 'flex-end',
    '.form-group': {
      flex: 1,
      marginBottom: 0,
      marginRight: '.6rem',
    },
  }))

  return (
    <Form onSubmit={handleSubmit} css={styles}>
      <FormGroup>
        <Label for="docs-upload-input">{t('contract.docs.add')}</Label>
        <CustomInput
          type="file"
          id="docs-upload-input"
          name="file"
          label={t('contract.docs.selectFile')}
          innerRef={inputRef}
          onChange={() => {
            setTouched(true)
          }}
        />
      </FormGroup>

      <Button
        disabled={!touched || status === 'loading'}
        type="submit"
        css={{ display: 'flex', alignItems: 'center' }}
      >
        {status === 'loading' && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
        {t('global.validate')}
      </Button>
    </Form>
  )
}
