import React from 'react'
import { useSelector } from 'react-redux'

import { getContractId } from 'store/selection'
import { ContractPerimetre } from 'components/sections/ContractPerimetre'

export const ContractPagePerimetre: React.FC = () => {
  const id = useSelector(getContractId)
  if (!id) return null

  return <ContractPerimetre />
}
