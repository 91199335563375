import * as Yup from 'yup'
import { TFunction } from 'i18next'


export const schemaCoso = (t: TFunction) => () =>
  Yup.object().shape(
    {
   
      Type: Yup.number().required(t('global.requiredField')),
      Debut: Yup.date()
        .when('Fin', (Fin: any, schema: any) => Fin && schema.max(Fin, t('global.startDateError')))
        .required(t('global.requiredField')),
      Fin: Yup.date()
        .when(
          'Debut',
          (Debut: any, schema: any) => Debut && schema.min(Debut, t('global.endDateError')),
        ),
      Unite: Yup.number().min(1).max(58).required(t('global.requiredField')),
      Pas: Yup.number().min(1).max(10).required(t('global.requiredField')),
      Csv: Yup.number(),
      file: Yup.mixed()
     
    },
    [['Debut', 'Fin']],
  )
