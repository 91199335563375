import React, { FC, Suspense, useState } from 'react'
import { Button, ListGroup, ListGroupItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { cx } from '@emotion/css'
import { lighten } from 'polished'

import { EnergyIcon, Loader, ErrorBoundary } from 'components/atoms'
import { usePeeLots } from 'api'

const Lots: FC<{ pee: Pee }> = ({ pee }) => {
  const { t } = useTranslation()
  const lots = usePeeLots({ peeId: pee.id })
  return (
    <>
      <h6 className="mb-3">{t('contract.scope.attachedLots', { name: pee.PeeClef })}</h6>

      {lots.length === 0 ? (
        <p>{t('contract.scope.none')}</p>
      ) : (
        <ListGroup>
          {lots.map((lot) => (
            <ListGroupItem key={lot.id}>{lot.nom}</ListGroupItem>
          ))}
        </ListGroup>
      )}
    </>
  )
}

export const PeeDialog: React.FC<{
  pees: Pee[]
  onConfirm: () => void
  close: () => void
}> = ({ pees, onConfirm, close }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)

  return (
    <div>
      <div
        css={(theme: any) => ({
          display: 'flex',
          height: 400,
          h6: {
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 1,
            paddingBottom: 16,
            margin: 0,
            fontSize: 18,
            fontWeight: 700,
            lineHeight: 1.5,
          },
          '.pees': {
            flexShrink: 0,
            overflow: 'auto',
            borderRight: '1px solid #ddd',
            marginRight: 30,
            maxWidth: 250,
            '.pee-btn': {
              padding: '5px 10px 5px 4px',
              cursor: 'pointer',
              margin: '4px 15px 4px 2px',
              display: 'flex',
              svg: {
                flexShrink: 0,
                marginTop: 3,
              },
              '&.active': {
                background: lighten(0.4, theme.cyan),
              },
            },
          },
          '.lots': {
            position: 'relative',
            flexGrow: 1,
          },
        })}
      >
        {pees.length > 1 && (
          <div className="pees">
            <h6>{t('global.pees')}</h6>

            {pees.map((pee, i) => (
              <div
                key={pee.id}
                className={cx('pee-btn', { active: i === index })}
                role="button"
                tabIndex={0}
                onClick={() => setIndex(i)}
                onKeyPress={() => setIndex(i)}
              >
                <EnergyIcon id={pee.PeeNrjId} />
                <span>{pee.PeeClef}</span>
              </div>
            ))}
          </div>
        )}

        <div className="lots">
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Lots pee={pees[index]} />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>

      <footer css={{ textAlign: 'center', marginTop: '2rem' }}>
        <Button color="secondary" onClick={close} className="mr-3">
          {t('global.cancel')}
        </Button>

        <Button color="danger" onClick={onConfirm}>
          {t('contract.scope.deleteConfirm', { count: pees.length })}
        </Button>
      </footer>
    </div>
  )
}
