import React from 'react'
import ReactNotification from 'react-notifications-component'

import { useTheme } from 'hooks'
import { UserPanel } from 'commons'
import { useBoolean } from 'hooks'
import { AppMenu } from 'components/templates/AppMenu'
import { Contracts } from 'components/sections/Contracts'
import { Sidebar } from 'components/templates/Sidebar'
import { SlidePanel } from 'components/atoms'

export const AppLayout: React.FC = (props) => {
  const theme = useTheme() as any
  const [isUserPanelOpen, toggleUserPanel, closeUserPanel] = useBoolean(false)
  const [isSearchPanelOpen, toggleSearchPanel, closeSearchPanel] = useBoolean(false)

  return (
    <>
      <ReactNotification />
      <div
        css={{
          height: '100%',
          overflowX: 'hidden',
          backgroundColor: theme.white,
          display: 'grid',
          gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
          gridTemplateRows: '70px calc(100% - 70px)',
          gridTemplateAreas: `
          'aside header'
          'aside main'
        `,
        }}
      >
        <header className="shadow" css={{ gridArea: 'header', backgroundColor: theme.secondary }}>
          <AppMenu toggleUserPanel={toggleUserPanel} />
        </header>

        <aside className="shadow" css={{ gridArea: 'aside', backgroundColor: theme.secondary }}>
          <Sidebar {...{ toggleSearchPanel, isSearchPanelOpen, closeSearchPanel }} />
        </aside>

        <div css={{ gridArea: 'main' }}>
          {props.children}

          <SlidePanel
            isOpen={isSearchPanelOpen}
            close={closeSearchPanel}
            width={480}
            css={{
              left: theme.sidebarWidth,
              width: `calc(100% - ${theme.sidebarWidth}px)`,
            }}
          >
            <Contracts done={closeSearchPanel} />
          </SlidePanel>

          <SlidePanel isOpen={isUserPanelOpen} close={closeUserPanel} width={480} position="right">
            <UserPanel done={closeUserPanel} />
          </SlidePanel>
        </div>
      </div>
    </>
  )
}
