import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'

import { useContracts } from 'api'
import { getContractId } from 'store/selection'

export const useContractsById = () => {
  const { data } = useContracts()
  return useMemo(() => {
    return data === null ? {} : keyBy(data, 'id')
  }, [data])
}

export const useContract = (id: number) => {
  const contracts = useContractsById()
  return contracts?.[id] ?? null
}

export const useCurrentContract = () => {
  const contracts = useContractsById()
  const id = useSelector(getContractId)
  if (id === null) return null
  return contracts?.[id] ?? null
}
