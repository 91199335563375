import React from 'react'
import { useSelector } from 'react-redux'
import { Table, Input, Button, CustomInput, FormGroup, FormFeedback } from 'reactstrap'
import { FaPlusCircle, FaCheck, FaRedo, FaFileImport, FaFileExcel } from 'react-icons/fa'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import { useTranslation } from 'react-i18next'

import { getLotId } from 'store/selection'
import { useStyles, useBoolean, useCurrentContract, useCurrentLot } from 'hooks'
import { useLotOptions, useUpdateLotOptions } from 'api'
import { Loader, Dialog, ImportDialog } from 'components/atoms'
import { downloadCSV } from 'helpers/downloadFile'

type Ref = {
  Id: number
  Nom: String
}[]

type Item = Option & {
  status: null | 'update' | 'create'
  delete: boolean
}

const InputCell: React.FC<{
  value: string
  onChange: (value: string) => void
  type: 'number' | 'text'
  setErrorCount: any
  disabled: boolean
}> = ({ value, onChange, type, setErrorCount, disabled }) => {
  const { t } = useTranslation()
  const isNull = value.trim().length === 0
  const isWrong = type === 'number' && isNaN(parseInt(value))
  const invalid = !disabled && (isNull || isWrong)

  const first = React.useRef(true)

  React.useEffect(() => {
    if (invalid) {
      setErrorCount((n: number) => n + 1)
    } else if (!first.current) {
      setErrorCount((n: number) => n - 1)
    }
    first.current = false
  }, [invalid, setErrorCount])

  return (
    <FormGroup css={{ margin: 0 }}>
      <Input
        type={type}
        value={value}
        invalid={invalid}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value.toString())
        }}
      />
      <FormFeedback>{isNull ? t('global.requiredField') : t('global.invalidField')}</FormFeedback>
    </FormGroup>
  )
}

const BoolCell: React.FC<{
  id: string
  value: string
  onChange: (value: string) => void
  disabled: boolean
}> = ({ id, value, onChange, disabled }) => {
  return (
    <FormGroup css={{ margin: 0 }}>
      <CustomInput
        type="checkbox"
        checked={value === 'True'}
        id={id}
        label=""
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.checked ? 'True' : 'False')
        }}
      />
    </FormGroup>
  )
}

const SelectCell: React.FC<{
  value: string
  options: Ref
  onChange: (value: string) => void
  setErrorCount: any
  disabled: boolean
}> = ({ value, options, onChange, setErrorCount, disabled }) => {
  const { t } = useTranslation()
  const isNull = value.trim().length === 0
  const isWrong = !options.some((o) => o.Id === Number(value))
  const invalid = !disabled && (isNull || isWrong)

  const first = React.useRef(true)

  React.useEffect(() => {
    if (invalid) {
      setErrorCount((n: number) => n + 1)
    } else if (!first.current) {
      setErrorCount((n: number) => n - 1)
    }
    first.current = false
  }, [invalid, setErrorCount])

  return (
    <FormGroup css={{ margin: 0 }}>
      <Input
        type="select"
        value={value}
        invalid={invalid}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value.toString())
        }}
      >
        {invalid && <option />}
        {(options || []).map((o) => (
          <option key={o.Id} value={o.Id}>
            {o.Nom}
          </option>
        ))}
      </Input>
      <FormFeedback>{isNull ? t('global.requiredField') : t('global.invalidField')}</FormFeedback>
    </FormGroup>
  )
}

export const LotOptions: React.FC<{ type: OptionType }> = ({ type, ...props }) => {
  const { t } = useTranslation()
  const contract = useCurrentContract()
  const lot = useCurrentLot()
  const [isModalOpen, toggleModal, closeModal] = useBoolean(false)

  const refs = mapValues(
    keyBy(
      (type?.Parametres ?? []).filter((p) => p.ValeursRef),
      'Id',
    ),
    (p) => p.ValeursRef,
  )

  const lotId = useSelector(getLotId)
  const [mutate, { data: updated, status, error: mutateError }] = useUpdateLotOptions(
    type.OptTypeId,
  )
  const { isFetching, error: queryError, data: options } = useLotOptions(type.OptTypeId)
  const [formData, setFormData] = React.useState<Item[]>([])
  const [errorCount, setErrorCount] = React.useState(0)

  const initFormData = React.useCallback(() => {
    if (!options) return
    setFormData(options.map((o) => ({ ...o, status: null, delete: false })))
    setErrorCount(0)
  }, [options])

  React.useEffect(() => {
    initFormData()
  }, [initFormData, options, updated])

  const add = () =>
    setFormData([
      ...formData,
      {
        OptTypeId: type.OptTypeId,
        Nom: type.Nom,
        Parametres: type.Parametres.map((p) => ({
          LotId: lotId,
          Valeur: p.Type === 5 ? 'False' : '',
          Option: {
            Id: p.Id,
            Type: p.Type,
          },
        })),
        status: 'create',
        delete: false,
      },
    ])

  const addOptions = (options: { [key: number]: string }[]) => {
    console.log('options', options)
    const newOptions = options.map((o) => ({
      OptTypeId: type.OptTypeId,
      Nom: type.Nom,
      Parametres: type.Parametres.map((p) => {
        const options = refs[p.Id]
        let value = o[p.Id]
        if ([3, 9].includes(p.Type)) {
          value = options?.find((option) => option.Nom.toString() === o[p.Id])?.Id.toString() ?? ''
        }

        return {
          LotId: lotId,
          Valeur: value,
          Option: {
            Id: p.Id,
            Type: p.Type,
          },
        }
      }),
      status: 'create' as any,
      delete: false,
    }))

    setFormData([...formData, ...newOptions])
  }

  const toggle = (index: number) =>
    setFormData(
      formData.map((item, i) => ({
        ...item,
        delete: i === index ? !item.delete : item.delete,
      })),
    )

  const setValue = (optIndex: number, paramIndex: number, value: string) => {
    const data = cloneDeep(formData)

    data[optIndex].delete = false
    if (data[optIndex].status !== 'create') data[optIndex].status = 'update'

    data[optIndex].Parametres[paramIndex].Valeur = value

    setFormData(data)
  }

  const touched = formData.some((item) => item.delete || item.status !== null)

  const handleExport = () => {
    const header = type.Parametres.map((p) => p.Commentaire)

    const body =
      options?.map((o) =>
        o.Parametres.map((p) => {
          if (![3, 9].includes(p.Option.Type)) return p.Valeur
          const options = refs[p.Option.Id]
          return options?.find((option) => option.Id.toString() === p.Valeur)?.Nom ?? ''
        }),
      ) ?? []

    const content = [header, ...body].map((row) => row.join(';')).join('\n')
    const filename = `Options ${contract?.CtrNomCourt ?? ''} - ${lot?.nom ?? ''} - ${type.Nom}.csv`
    downloadCSV(filename, content)
  }

  const css = useStyles(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '>div': {
      flex: 1,
      overflow: 'auto',
      position: 'relative',
      borderTop: '1px solid #dee2e6',
      borderBottom: '1px solid #dee2e6',
    },
    table: {
      position: 'absolute',
      border: '1px solid #dee2e6',
      borderTop: 'none',
      top: 0,
      width: 'unset',
      minWidth: '100%',
      tableLayout: 'fixed',
      margin: 0,
      thead: {
        tr: {
          background: '#fff',
          th: {
            border: 'none',
            zIndex: 1,
            position: 'sticky',
            top: 0,
            background: '#fff',
            verticalAlign: 'middle',
            textAlign: 'center',
            minWidth: 100,
            '&:nth-of-type(1)': {
              minWidth: 40,
              maxWidth: 40,
              width: 40,
            },
          },
        },
      },
      tbody: {
        opacity: isFetching ? 0.5 : 1,
        td: {
          border: 'none',
        },
      },
    },
    footer: {
      marginTop: '1.5rem',
      flex: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.buttons': {
        'button:not(:last-child)': {
          marginRight: '.6rem',
        },
      },
      button: {
        svg: {
          marginRight: '.4rem',
          verticalAlign: 'middle',
        },
      },
    },
    '.custom-switch': {
      paddingLeft: '2.5rem !important',
    },
  }))

  if ((queryError || mutateError) && !isFetching && status !== 'loading')
    return <p className="text-danger">{t('global.error')}</p>

  return (
    <div css={css} {...props}>
      <div>
        <Table striped hover size="sm" className="bg-white">
          <thead>
            <tr>
              <th className="shadow-sm">&nbsp;</th>
              {type.Parametres.map((p) => (
                <th className="shadow-sm" key={p.Id}>
                  {p.Commentaire}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isFetching &&
              formData.map((option, index) => {
                const optionParams = keyBy(option.Parametres, 'Option.Id')
                return (
                  <tr key={index}>
                    <td
                      css={{
                        zIndex: 0,
                        textAlign: 'center',
                        verticalAlign: 'middle !important',
                        background:
                          option.status === 'create' || option.status === 'update'
                            ? '#515BB3 !important'
                            : 'unset',
                      }}
                      title={t('global.delete')}
                    >
                      <CustomInput
                        type="switch"
                        id={`toggle${index}`}
                        defaultChecked={!option.delete}
                        onClick={() => toggle(index)}
                      />
                    </td>
                    {type.Parametres.map((p) => optionParams[p.Id]).map((p, i) => (
                      <td key={i}>
                        {[3, 9, 14].includes(p.Option.Type) ? (
                          <SelectCell
                            value={p.Valeur}
                            options={refs[p.Option.Id] || []}
                            onChange={(value) => setValue(index, i, value)}
                            disabled={option.delete}
                            {...{ setErrorCount }}
                          />
                        ) : [5].includes(p.Option.Type) ? (
                          <BoolCell
                            id={`${p.Option.Id}-${index}-${i}`}
                            value={p.Valeur}
                            onChange={(value) => setValue(index, i, value)}
                            disabled={option.delete}
                          />
                        ) : (
                          <InputCell
                            value={
                              [1, 2, 12].includes(p.Option.Type)
                                ? p.Valeur.replace(',', '.')
                                : p.Valeur
                            }
                            type={[1, 2, 12].includes(p.Option.Type) ? 'number' : 'text'}
                            onChange={(value) => {
                              setValue(index, i, value)
                            }}
                            disabled={option.delete}
                            {...{ setErrorCount }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
          </tbody>
        </Table>

        {isFetching && <Loader />}
      </div>

      <footer>
        <div className="buttons">
          <Button onClick={add}>
            <FaPlusCircle />
            <span>{t('lot.options.addOption')}</span>
          </Button>

          <Button onClick={toggleModal} disabled={typeof navigator.clipboard === 'undefined'}>
            <FaFileImport />
            <span>{t('global.import')}</span>
          </Button>

          <Button onClick={handleExport}>
            <FaFileExcel />
            <span>{t('global.export')}</span>
          </Button>
        </div>

        <div className="buttons">
          <Button color="light" disabled={!touched} onClick={initFormData}>
            <FaRedo />
            <span>{t('global.reset')}</span>
          </Button>

          <Button
            disabled={!touched || errorCount > 0}
            onClick={() => {
              const data = formData.map((o) => ({
                ...o,
                Parametres: o.Parametres.map((p) => ({
                  ...p,
                  Valeur: [1, 2, 12].includes(p.Option.Type)
                    ? p.Valeur.replace('.', ',')
                    : p.Valeur,
                })),
              }))

              const payload = {
                Create: data
                  .filter((o) => o.status === 'create' && !o.delete)
                  .map((o) => omit(o, ['status', 'delete'])),
                Update: data
                  .filter((o) => o.status === 'update' && !o.delete)
                  .map((o) => omit(o, ['status', 'delete'])),
                Delete: data
                  .filter((o) => o.status !== 'create' && o.delete)
                  .map((o) => omit(o, ['status', 'delete'])),
              }

              mutate({ payload })
            }}
          >
            <FaCheck />
            <span>{t('global.validate')}</span>
          </Button>
        </div>

        <Dialog isOpen={isModalOpen} close={closeModal} css={{ width: 800 }}>
          {isModalOpen && (
            <ImportDialog
              setData={addOptions}
              close={closeModal}
              columns={type.Parametres.map((p) => ({ id: p.Id.toString(), label: p.Commentaire }))}
              isValidCell={(value) => typeof value === 'string' && value.trim().length > 0}
            />
          )}
        </Dialog>
      </footer>
    </div>
  )
}
