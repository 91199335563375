import React, { FC } from 'react'
import { useField, useFormikContext } from 'formik'
import { Label, Input, FormFeedback } from 'reactstrap'
import moment, { Moment } from 'moment'
import DateTime from 'react-datetime'

import { dateFormat } from 'helpers/formatter'

export const DateField: FC<{
  name: string
  label: string
  size?: 'sm' | 'lg'
}> = ({ name, label, size, ...props }) => {
  const { isSubmitting, validateForm } = useFormikContext()
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name)

  const [inputValue, setInputValue] = React.useState<string | null>(null)

  const format = dateFormat()

  React.useEffect(() => {
    const date = moment(value)
    const dateValue = date.isValid() ? date.format(format) : ''
    setInputValue(dateValue)
  }, [format, value])

  if (inputValue === null) return null

  return (
    <div {...props}>
      <Label size={size} for={name}>
        {label}
      </Label>

      <DateTime
        initialViewMode="days"
        updateOnView="days"
        dateFormat={format}
        timeFormat={false}
        utc={true}
        closeOnSelect={true}
        value={inputValue}
        onChange={(date: Moment | string) => {
          if (typeof date === 'string') {
            setInputValue(date)
          } else {
            setInputValue(date.format(format))
            setValue(date.format())

            setTimeout(() => {
              setTouched(true)
              validateForm()
            }, 400)
          }
        }}
        renderInput={({ value, onChange, ...props }: any) => {
          return (
            <>
              <Input
                {...props}
                placeholder={format}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                invalid={!!error && !!touched}
                disabled={isSubmitting}
                onBlur={() => {
                  const m = moment(inputValue, format, true)
                  const date = m.isValid() ? m.format() : inputValue
                  setValue(date)
                  // if (m.isValid()) {
                  //   setTimeout(() => {
                  //     setTouched(true)
                  //     validateForm()
                  //   }, 400)
                  // }
                }}
              />
              {error && touched && <FormFeedback>{error}</FormFeedback>}
            </>
          )
        }}
      />
    </div>
  )
}
