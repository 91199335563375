import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { autorityUrl } from '../helpers/env'

export const Logout: FC = () => {
  localStorage.clear()
  sessionStorage.clear()
  return <Redirect to={autorityUrl as string} push />
}
