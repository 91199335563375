import React from 'react'
import { FormFeedback } from 'reactstrap'
import { useFormikContext, useField } from 'formik'

export const BooleanField: React.FC<{
  name: string
}> = ({ name }) => {
  const [{ value }, meta, { setValue, setTouched }] = useField({ name })
  const { isSubmitting } = useFormikContext()

  return (
    <div>
      <div>
        <label className="mr-4">
          <input
            name="name"
            type="radio"
            disabled={isSubmitting}
            defaultChecked={value?.toLowerCase() === 'true'}
            onChange={() => {
              setValue('true')
              setTimeout(() => {
                setTouched(true)
              }, 100)
            }}
          />
          <span className="ml-2">Oui</span>
        </label>

        <label>
          <input
            name="name"
            type="radio"
            disabled={isSubmitting}
            defaultChecked={value?.toLowerCase() === 'false'}
            onChange={() => {
              setValue('false')
              setTimeout(() => {
                setTouched(true)
              }, 100)
            }}
          />
          <span className="ml-2">Non</span>
        </label>
      </div>

      {meta.error && meta.touched && (
        <FormFeedback style={{ display: 'block' }}>{meta.error}</FormFeedback>
      )}
    </div>
  )
}
