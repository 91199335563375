import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getLotId } from 'store/selection'

export const useLotOptionTypes = () => {
  const ready = useSelector(getReady)
  const lotId = useSelector(getLotId)
  const { data } = useQuery<OptionType[], AnyQueryKey>(
    ready && lotId !== null && ['lotOptionTypes', { lotId }],
    () =>
      superFetch({
        url: `lots/${lotId}/options/type`,
      }),
  )
  return data
}

export const useLotOptionTypesRef = () => {
  const ready = useSelector(getReady)
  const lotId = useSelector(getLotId)
  const { data } = useQuery<OptionType[], AnyQueryKey>(
    ready && lotId !== null && ['lotOptionTypesRef', { lotId }],
    () =>
      superFetch({
        url: `lots/${lotId}/options/type/ref`,
      }),
  )
  return data
}

export const useLotOptions = (typeId: number) => {
  const ready = useSelector(getReady)
  const lotId = useSelector(getLotId)
  return useQuery<Option[], AnyQueryKey>(
    ready && lotId !== null && typeId !== null && ['lotOptions', { lotId, typeId }],
    () =>
      superFetch({
        url: `lots/${lotId}/options/${typeId}`,
      }),
    { suspense: false },
  )
}

export const useUpdateLotOptions = (typeId: number) => {
  const lotId = useSelector(getLotId)

  return useMutation<any, { payload: object }>(
    ({ payload }) =>
      superFetch<Perimetre>({
        url: `lots/${lotId}/options`,
        method: 'POST',
        body: payload,
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['lotOptions', { lotId, typeId }])
      },
    },
  )
}
