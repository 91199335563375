import { useEffect } from 'react'

type Callback = (s: string) => void

export const usePaste = (callback: Callback) => {
  useEffect(() => {
    const pasteHandler = () => {
      navigator.clipboard
        .readText()
        .then(callback)
        .catch((error) => {
          console.log(error)
        })
    }
    window.addEventListener('paste', pasteHandler)
    return () => {
      window.removeEventListener('paste', pasteHandler)
    }
  }, [callback])
}
