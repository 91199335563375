import React from 'react'
import { CellProps } from 'react-table'
import { Input } from 'reactstrap'
import cloneDeep from 'lodash/cloneDeep'

export const InputCell: React.FC<CellProps<any> & {
  setData: (data: any) => void
  invalid: boolean
}> = ({ row, column, cell, setData, invalid }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const index = (column as any).index

  React.useEffect(() => {
    if (index === 1 && row.index === 0 && inputRef.current) {
      inputRef.current.focus()
    }
  }, [index, row.index])

  return (
    <Input
      bsSize="sm"
      innerRef={inputRef}
      value={cell.value || ''}
      onChange={(e) => {
        const value = e.currentTarget.value
        setData((data: any) => {
          const newData = cloneDeep(data)
          newData[row.index][column.id] = value
          return newData
        })
      }}
      invalid={invalid}
      css={{
        height: 'unset',
        padding: 0,
        border: 0,
        borderRadius: 0,
        boxShadow: 'none',
      }}
    />
  )
}
