import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useSuppliers, useReferentiel } from 'api'
import { Select, Period } from 'components/atoms'
import { setCountry, setSupplier, setStartYear, setEndYear, getFilters, setValidationStatus } from 'store/selection'

export const ContractFilters: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { country, supplier, startYear, endYear, validationStatus } = useSelector(getFilters)
  const supplierOptions = useSuppliers()
  const { ListePays, ListValidationStatus } = useReferentiel()

  return (
    <div
      css={{
        display: 'grid',
      }}
    >
      <div
        css={{
          display: 'flex',
          '>*': {
            marginBottom: '2rem',
            '&:not(:last-child)': {
              marginRight: '1rem',
            },
          },
        }}
      >
        {/* {supplierOptions.length > 1 && (
          <Select
            label={t('global.supplier')}
            value={supplier}
            onChange={(id: any) => dispatch(setSupplier(id))}
            options={supplierOptions}
          />
        )} */}

        {ListePays.length > 1 && (
          <Select
            label={t('global.country')}
            value={country}
            onChange={(id: any) => dispatch(setCountry(id))}
            options={ListePays}
          />
        )}
      </div>

      <div
       css={{
        display: 'flex',
        '>*': {
          '&:not(:last-child)': {
            marginRight: '2rem',
          },
        },
      }}>
      <Period
        start={startYear}
        end={endYear}
        setStart={(year) => dispatch(setStartYear(year))}
        setEnd={(year) => dispatch(setEndYear(year))}
      />

      {ListValidationStatus.length > 1 && (
        <Select
          label={t('contract.infos.labels.validationStatus')}
          value={validationStatus}
          onChange={(id: any) => dispatch(setValidationStatus(id))}
          options={ListValidationStatus}
        />    
      )}
      </div>
    </div>
  )
}
