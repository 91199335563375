import React from 'react'
import { useSelector } from 'react-redux'

import { getContractId } from 'store/selection'
import { Section } from 'components/atoms'
import { ContractOptions } from 'components/sections/ContractOptions'

export const ContractPageOptions: React.FC = () => {
  const id = useSelector(getContractId)
  if (!id) return null

  return (
    <>
      <Section>
        <ContractOptions />
      </Section>
    </>
  )
}
