import React from 'react'

import { TreeSelectProps } from './Tree.types'
import { TreeProvider } from './TreeContext'
import { TreeList } from './TreeList'
import { TreeButtons } from './TreeButtons'

export const TreeSelect: React.FC<any> = React.memo(
  ({ type, tree, checked, onCheck, showButtons = false, ...props }) => {
    return (
      <TreeProvider {...{ type, tree, checked, onCheck }}>
        {showButtons && <TreeButtons />}
        <TreeList tree={tree} {...props} />
      </TreeProvider>
    )
  },
)
