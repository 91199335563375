import React from 'react'
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap'
import { FaPlusCircle } from 'react-icons/fa'

import { useLotOptionTypesRef } from 'api'
import { useStyles } from 'hooks'

export const LotAddOptionType: React.FC<{
  types: OptionType[]
  addType: (type: OptionType) => void
}> = ({ types, addType }) => {
  const [value, setValue] = React.useState<number | null>(null)
  const originalRef = useLotOptionTypesRef()

  const ref = React.useMemo(
    () => originalRef?.filter((type) => !types.some((t) => t.OptTypeId === type.OptTypeId)) ?? [],
    [originalRef, types],
  )

  React.useEffect(() => {
    if (ref && ref.length > 0) {
      setValue(ref[0].OptTypeId)
    }
  }, [ref])

  const css = useStyles(() => ({
    padding: '1rem',
    display: 'flex',
    '>*:not(:last-child)': {
      marginRight: '1.5rem',
    },
    select: {
      padding: '0.3rem 0.3rem',
    },
  }))

  if (!ref || ref.length === 0) return null

  return (
    <div css={css}>
      <InputGroup>
        <Input type="select" value={value || ''} onChange={(e) => setValue(Number(e.target.value))}>
          {ref.map((type) => (
            <option key={type.OptTypeId} value={type.OptTypeId}>
              {type.Nom}
            </option>
          ))}
        </Input>

        <InputGroupAddon addonType="append">
          <Button
            onClick={() => {
              const type = ref.find((type) => type.OptTypeId === value)
              if (type) addType(type)
            }}
          >
            <FaPlusCircle />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
}
