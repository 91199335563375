import React from 'react'
import { Table } from 'reactstrap'
import { TextField } from './TextField'
import { DateField } from './DateField'
import { SelectField } from './SelectField'
import { BooleanField } from './BooleanField'

export const TarifTable: React.FC<{
  data: any
  getName: (index: number) => string
}> = ({ data, getName }) => {
  return (
    <Table
      size="sm"
      css={{
        margin: 0,
        td: {
          border: 'none',
          verticalAlign: 'middle',
          padding: 4,
          '&:nth-of-type(2)': { width: 280 },
        },
      }}
    >
      <tbody>
        {Object.values(data).map((item: any, index) => {
          const { Libelle, ArtType, ValeursRef, Valeur, ArticleId } = item
          const name = getName(ArticleId)

          return (
            <tr key={name}>
              <td>{Libelle}</td>
              <td>
                {ValeursRef && ValeursRef.length > 0 ? (
                  <SelectField name={name} reference={ValeursRef} />
                ) : [4].includes(ArtType) ? (
                  <DateField name={name} />
                ) : [5].includes(ArtType) ? (
                  <BooleanField name={name} />
                ) : (
                  <TextField
                    name={name}
                    type={
                      Valeur !== '' &&
                      (!isNaN(Number(Valeur)) || !isNaN(Number(Valeur.replace(',', '.'))))
                        ? 'number'
                        : 'text'
                    }
                  />
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
