import React, { FC, ReactNode } from 'react'
import { Button } from 'reactstrap'

interface Props extends React.ComponentProps<typeof Button> {
  Icon?: ReactNode
  iconPos?: 'left' | 'right'
}

const CustomButton: FC<Props> = ({ Icon, iconPos = 'left', children, ...props }) => {
  const isLeft = iconPos === 'left'
  const spacing = '0.6em'
  return (
    <Button
      css={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: isLeft ? 'row' : 'row-reverse',
      }}
      {...props}
    >
      <span css={{ marginRight: Icon && isLeft ? spacing : 0 }}>{Icon}</span>
      <span css={{ marginRight: Icon && !isLeft ? spacing : 0 }}>{children}</span>
    </Button>
  )
}

export { CustomButton as Button }
