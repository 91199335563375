export default {
  USER_LOGIN_REQUEST: 'commons/user/USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'commons/user/USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'commons/user/USER_LOGIN_FAILURE',
  USER_LOGOUT_REQUEST: 'commons/user/USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS: 'commons/user/USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'commons/user/USER_LOGOUT_FAILURE',
  PASSWORD_RESET_REQUEST: 'commons/user/PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'commons/user/PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'commons/user/PASSWORD_RESET_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'commons/user/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_FAILURE: 'commons/user/CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_SUCCESS: 'commons/user/CHANGE_PASSWORD_SUCCESS',
  USER_INFO_REQUEST: 'commons/user/USER_INFO_REQUEST',
  USER_INFO_SUCCESS: 'commons/user/USER_INFO_SUCCESS',
  USER_INFO_FAILURE: 'commons/user/USER_INFO_FAILURE',
  USER_FORM_POST_REQUEST: 'commons/user/USER_FORM_POST_REQUEST',
  USER_FORM_POST_SUCCESS: 'commons/user/USER_FORM_POST_SUCCESS',
  USER_FORM_POST_FAILURE: 'commons/user/USER_FORM_POST_FAILURE',
  USER_FORM_UPDATE: 'commons/user/USER_FORM_UPDATE',
  USER_FORM_RESET: 'commons/user/USER_FORM_RESET',
  USER_INFOS_SUCCESS: 'commons/user/USER_INFOS_SUCCESS',
}
