import React, { Suspense } from 'react'

import { ErrorBoundary, Loader } from 'components/atoms'

export const Wrapper: React.FC<any> = ({ children, ...props }) => {
  return (
    <div
      css={{
        position: 'relative',
        // border: '1px solid red',
      }}
      {...props}
    >
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </ErrorBoundary>
    </div>
  )
}
