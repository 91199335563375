import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Button } from 'reactstrap'

import { useStyles } from 'hooks'

type Props = {
  isOpen: boolean
  close: () => void
}

export const Dialog: React.FC<any> = ({ isOpen, close, children, ...props }) => {
  const styles = useStyles((theme: any) => ({
    zIndex: 2000,
    '[data-reach-dialog-content]': {
      boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
      position: 'relative',
      padding: theme.spacing * 2,
    },
    'button.close': {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: 10,
    },
  }))

  return (
    <DialogOverlay css={styles} isOpen={isOpen} onDismiss={close}>
      <DialogContent aria-labelledby="new-contract" {...props}>
        {children}
        <Button close onClick={close} />
      </DialogContent>
    </DialogOverlay>
  )
}
