import { createAction, createReducer } from '@reduxjs/toolkit'

import { RootState } from 'store'

const initialState = {
  ready: false,
}

// Selectors
export const getReady = (state: RootState) => state.api.ready

// Actions
export const setReady = createAction<void, 'api/SET_READY'>('api/SET_READY')

// Reducer
export const api = createReducer(initialState, (builder) =>
  builder.addCase(setReady, (state) => ({ ...state, ready: true })),
)
