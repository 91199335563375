import React from 'react'
import { useTranslation } from 'react-i18next'

import { useContractDocs } from 'api'
import { useStyles } from 'hooks'
import { DocsForm } from './DocsForm'
import { DocsTable } from './DocsTable'

export const ContractDocs: React.FC = () => {
  const { t } = useTranslation()
  const docs = useContractDocs()

  const styles = useStyles((theme: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '>*:not(:last-child)': {
      marginBottom: theme.spacing * 2,
    },
  }))

  return (
    <div css={styles}>
      {docs &&
        (docs.length > 0 ? <DocsTable data={docs} /> : <div>{t('contract.docs.empty')}</div>)}

      <DocsForm />
    </div>
  )
}
