import React, { FC } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

interface Props {
  id: string
  label: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: any
  required?: boolean
}

export const TextInput: FC<Props> = ({
  id,
  label,
  onChange,
  value,
  type = 'text',
  required = false,
}) => (
  <FormGroup>
    <Label for={id}>
      {label}
      {required && ' *'}
    </Label>
    <Input id={id} value={value} onChange={onChange} required={required} type={type} />
  </FormGroup>
)
