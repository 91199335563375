import React from 'react'
import { CustomInput } from 'reactstrap'

import { Leaf } from './Tree.types'
import { useTreeContext } from './TreeContext'

export const TreeLeaf: React.FC<Leaf> = React.memo(({ value, label }) => {
  const ctx = useTreeContext()
  const checked = ctx.isLeafChecked(value)

  return (
    <CustomInput
      type={ctx.type}
      id={value.toString()}
      label={label}
      checked={checked}
      onChange={ctx.onLeafChecked(value)}
      css={{
        marginLeft: ctx.type === 'radio' ? 4 : 22,
        marginBottom: '0.1rem',
      }}
    />
  )
})
