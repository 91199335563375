import React from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useContract } from 'api'
import { useCreateVirtualSite } from 'api/contractVirtualSite'
import { SiteForm } from './SiteForm'
import { schema } from './SiteSchema'
import { addNotification } from 'notification'

export const SiteCreate: React.FC<{
  done: () => void
}> = ({ done = () => {} }) => {
  const { t } = useTranslation()
  const contract = useContract() as Contract
  const [mutate, { data, status }] = useCreateVirtualSite()
  const history = useHistory()

  React.useEffect(() => {
    if (!data) return
    history.push(`/contrat/${contract.Id}/perimetre`)
    setTimeout(done)
    addNotification({
      type: 'success',
      message: t('contract.site.createSuccess', { name: data.SiteName }),
    })
  }, [contract, data, done, history, t])

  const submit = React.useCallback(
    async (values, actions) => {
      try {
        await mutate({
          contractId: contract.Id,
          virtualSiteRequest: values,
        })
        actions.setSubmitting(false)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.site.createErrorTitle'),
          message: (error as any).toString(),
        })
      }
    },
    [mutate, t],
  )

  const values: VirtualSitePEE = {
    SiteName: '',
    SiteUorId: null
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema(t)}
      onSubmit={submit}
    >
      {(props) => <SiteForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
