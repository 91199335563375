import React, { FC } from 'react'
import { FaUserCircle, FaChevronDown } from 'react-icons/fa'
import { Button } from 'reactstrap'
import { useRouteMatch } from 'react-router-dom'

import { useTheme } from 'hooks'

import { ContractTabs } from './ContractTabs'
import { LotTabs } from './LotTabs'

export const AppMenu: FC<{
  toggleUserPanel: () => void
}> = ({ toggleUserPanel }) => {
  const theme = useTheme() as any
  const matchLotRoute = useRouteMatch('/contrat/:contractId/lot/:lotId')
  const matchContractRoute = useRouteMatch('/contrat/:contracId')

  return (
    <div
      css={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: `0 ${theme.spacing * 2}px`,
        fontSize: '1.1rem',
        '.user-button': {
          opacity: 0.6,
          margin: '.8rem 0',
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            opacity: 1,
          },
          '>*:not(:last-child)': {
            marginRight: '.6rem',
          },
        },
      }}
    >
      {matchLotRoute ? <LotTabs /> : matchContractRoute ? <ContractTabs /> : <div />}

      <Button onClick={toggleUserPanel} className="user-button shadow-none" color="primary">
        <FaUserCircle size={20} />
        <FaChevronDown />
      </Button>
    </div>
  )
}
