import React, { FC, Suspense, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ContratConsommationForm } from 'components/sections/ContractPerimetre/ContratConsommationForm'
import { schemaCoso } from 'components/schemeConso'
import {useDownloadConsommation, useUpdateConsommation,cdcDownload} from 'api/Consommation'
import { addNotification } from 'notification'
import { ConsoBody,CdcBody } from 'types/api/ConsoBody'
import { downloadFile } from 'helpers/downloadFile'
import { useCurrentContract } from 'hooks'
import moment from 'moment'


export const ContratConsommationCreate:  React.FC<{
  done?: () => void
  peeClefId: string
  siteId: number
}> = ({ done, peeClefId, siteId}) => {
  const { t } = useTranslation()
  const [mutate, { status }] = useUpdateConsommation()
  const [ mutateDownload ] = useDownloadConsommation()
  const [cdcDownloadMutate,{}] = cdcDownload()
  const ctr = useCurrentContract()
  const [dataPrevu, setDataPrevu] = React.useState<Array<[number, number]>>([]);
  const [dataReel, setDataReel] = React.useState<Array<[number, number]>>([]);
  const values: Partial<ConsoBody> = {
    Nom: peeClefId,
    Type: 2, //Prevu
    Debut: moment(ctr?.CtrDateDebut, 'DD/MM/YYYY').add(23, 'hour').toISOString(),
    Fin: moment(ctr?.CtrDateFin,'DD/MM/YYYY').add(23, 'hour').toISOString(),
    Pas: 5, //Minute
    Unite: 1, //kW
    Csv: 3, //Une Collone de la conso
    file: new FormData()
  } 
  
  const submit = React.useCallback(
    async (values, actions) => {  
        try {
          if(values.file===null){
            addNotification({
              type: 'danger',
              title: t('contract.consommationPopover.requiredField'),
              message:t('contract.consommationPopover.requiredFieldFile') ,
            })

            return
          }
          await mutate({
            cons : values,
          })
           await displayCurve(values);
              addNotification({
                type: 'success',
                message:'success' ,
              })
        }
        catch (error) {
          addNotification({
            type: 'danger',
            title:t('contract.consommationPopover.updateErrorTitle'),
            message: t('contract.consommationPopover.requiredField'),
          })
        }
    },
    [mutate, t],
  )
  const displayCurve = React.useCallback(
    async (values) => {  
        try {
          const body : Partial<CdcBody> = {
            debut: values.Debut,
            fin: values.Fin,
            ids: [siteId],
            typeIds: "PEE",
            nrjs: [""],
            typesDonnees: [values.Type==6?"Mesure":"Prevu"],
            unite: values.Unite,
            agregation: "0",
            pas: values.Pas,
            hrs: ""
          }

          const bodyReel ={...body}
          bodyReel.typesDonnees=["Mesure"]
          const bodyPrevu ={...body}
          bodyPrevu.typesDonnees=["Prevu"]
          const cdcReel = await cdcDownloadMutate({ payload: bodyReel })
          if(cdcReel!=null)
          {
            const series=cdcReel?.series[0]?.data
            const newDataReel = series?.map((data:{x:number,y:number}) => ([data.x, data.y]));  
            setDataReel(newDataReel);
          }
          const cdcPrevu = await cdcDownloadMutate({ payload: bodyPrevu })
          if(cdcPrevu!=null)
          {
            const series=cdcPrevu?.series[0]?.data
            const newDataPrevu = series?.map((data:{x:number,y:number}) => ([data.x, data.y]));  
            setDataPrevu(newDataPrevu);
          }

        }
        catch (error) {
          addNotification({
            type: 'danger',
            title:t('contract.consommationPopover.updateErrorTitle'),
            message: t('contract.consommationPopover.requiredField'),
          })
         
        }
    },
    [cdcDownloadMutate, t],
  )
const handle = React.useCallback(
    async (values, actions) => {
    try {
      const filename = `${peeClefId}_${values.Debut}_${values.Fin}.csv`
      const { data } = await mutateDownload({ cons: values })
      downloadFile(filename, data,  { type: 'text/csv' })
      //displayCdc
      await displayCurve(values);
      addNotification({
          type: 'success',
          message:'success' ,
      })
    }
    catch (error) {
      addNotification({
        type: 'danger',
        title: t('global.error'),
        message: (error as any).toString(),
      })
    }},
    [mutateDownload, t],
  )
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={schemaCoso(t)}
        onSubmit={submit}
      >
      {(props) => <ContratConsommationForm handle={handle} peeClefId={peeClefId} displayCurve={displayCurve} dataReel={dataReel} dataPrevu={dataPrevu} isLoading={status === 'loading'} {...props} />}
    </Formik>
    </div>
  )
}
