import React, { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, FormGroup, Button, CustomInput } from 'reactstrap'
import { store } from 'react-notifications-component'

import { TextInput } from './TextInput'
import { fetchUserInfo, postUserForm, postSuccess, postError, userDetail } from '../../store/user'

const notification = {
  title: 'Profile',
  insert: 'top',
  container: 'bottom-left',
  animationOut: ['fadeOut'],
  dismiss: {
    duration: 3000,
    showIcon: true,
  },
}

const UserInfos: FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userInfo = useSelector(userDetail)
  const userPostSuccess = useSelector(postSuccess)
  const userPostError = useSelector(postError)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (userPostSuccess) {
      store.addNotification({
        ...notification,
        type: 'success',
        message: 'Your profile information has been saved successfully',
      })
    }
  }, [userPostSuccess])

  useEffect(() => {
    if (userPostError) {
      store.addNotification({
        ...notification,
        type: 'danger',
        message: userPostError,
      })
    }
  }, [userPostError])

  const [isInit, setIsInit] = useState(false)
  const [formData, setFormData] = useState<any>(null)

  const onChange = useCallback((data) => {
    setFormData(data)
    setTouched(true)
  }, [])

  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  useEffect(() => {
    if (!isInit && userInfo) {
      setFormData(userInfo)
      setIsInit(true)
    }
  }, [isInit, userInfo])

  if (!isInit) return null

  return (
    <Form
      css={{
        height: '100%',
        section: {
          height: 'calc(100% - 140px)',
          overflowY: 'auto',
          padding: 30,
        },
        footer: {
          padding: '0 15px',
          height: 70,
          borderTop: '1px solid #adb5bd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '>*:not(:last-child)': { marginRight: '0.6rem' },
        },
      }}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(postUserForm(formData))
        done()
      }}
    >
      <section>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0.6rem',
            '.form-group': {
              display: 'flex',
              '>*:not(:last-child)': { marginRight: '1rem' },
            },
          }}
        >
          <FormGroup>
            <CustomInput
              type="radio"
              id="userM"
              name="userGender"
              label={t('user.userInfo.male')}
              checked={formData.UsrCiviliteId === 1}
              onChange={() =>
                onChange({
                  ...formData,
                  UsrCiviliteId: 1,
                  UserCivilite: t('user.userInfo.male'),
                })
              }
            />
            <CustomInput
              type="radio"
              id="userF"
              name="userGender"
              label={t('user.userInfo.female')}
              checked={formData.UsrCiviliteId === 2}
              onChange={() =>
                onChange({
                  ...formData,
                  UsrCiviliteId: 2,
                  UserCivilite: t('user.userInfo.female'),
                })
              }
            />
          </FormGroup>

          <FormGroup>
            <CustomInput
              type="radio"
              id="userLanFr"
              name="userLan"
              label="FR"
              checked={formData.LanId === 1}
              onChange={() => onChange({ ...formData, LanId: 1 })}
            />
            <CustomInput
              type="radio"
              id="userLanEn"
              name="userLan"
              label="EN"
              checked={formData.LanId === 2}
              onChange={() => onChange({ ...formData, LanId: 2 })}
            />
          </FormGroup>
        </div>

        <TextInput
          id="userFirstname"
          value={formData.UsrNom}
          onChange={(e) => onChange({ ...formData, UsrNom: e.target.value })}
          label={t('user.userInfo.firstName')}
        />
        <TextInput
          id="userLastname"
          value={formData.UsrPrenom}
          onChange={(e) => onChange({ ...formData, UsrPrenom: e.target.value })}
          label={t('user.userInfo.lastName')}
        />
        <TextInput
          id="userEmail"
          value={formData.UsrEMail}
          onChange={(e) => onChange({ ...formData, UsrEMail: e.target.value })}
          label={t('user.userInfo.email')}
          required
        />
        <TextInput
          id="userAddress"
          value={formData.UsrAdresse && formData.UsrAdresse.AdrAdresse}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrAdresse: {
                ...formData.UsrAdresse,
                AdrAdresse: e.target.value,
              },
            })
          }
          label={t('user.userInfo.address')}
        />
        <TextInput
          id="userAddressComp1"
          value={formData.UsrAdresse && formData.UsrAdresse.AdrCompl1}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrAdresse: {
                ...formData.UsrAdresse,
                AdrCompl1: e.target.value,
              },
            })
          }
          label={t('user.userInfo.addressComp1')}
        />
        <TextInput
          id="userAddressComp2"
          value={formData.UsrAdresse && formData.UsrAdresse.AdrCompl2}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrAdresse: {
                ...formData.UsrAdresse,
                AdrCompl2: e.target.value,
              },
            })
          }
          label={t('user.userInfo.addressComp2')}
        />
        <TextInput
          id="userPc"
          value={formData.UsrAdresse && formData.UsrAdresse.AdrCodePostal}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrAdresse: {
                ...formData.UsrAdresse,
                AdrCodePostal: e.target.value,
              },
            })
          }
          label={t('user.userInfo.pc')}
        />
        <TextInput
          id="userCity"
          value={formData.UsrAdresse && formData.UsrAdresse.AdrVille}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrAdresse: {
                ...formData.UsrAdresse,
                AdrVille: e.target.value,
              },
            })
          }
          label={t('user.userInfo.city')}
        />
        <TextInput
          id="userPhone"
          value={formData.UsrNumTel}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrNumTel: e.target.value,
            })
          }
          label={t('user.userInfo.phone')}
        />
        <TextInput
          id="userMobile"
          value={formData.UsrNumMob}
          onChange={(e) =>
            onChange({
              ...formData,
              UsrNumMob: e.target.value,
            })
          }
          label={t('user.userInfo.mobile')}
        />
      </section>

      <footer>
        <Button type="button" color="secondary" onClick={done}>
          {t('user.userInfo.cancel')}
        </Button>

        <Button type="submit" color="primary" disabled={!touched}>
          {t('user.userInfo.save')}
        </Button>
      </footer>
    </Form>
  )
}

export default UserInfos
