import React, { FC } from 'react'
import { useField, useFormikContext } from 'formik'

import { Select, SelectOption } from 'components/atoms'

export const SelectField: FC<{
  name: string
  label: string
  options: SelectOption[]
  size?: 'sm' | 'lg'
  disabled?: boolean
}> = ({ name, label, options, size, disabled, ...props }) => {
  const { isSubmitting } = useFormikContext()
  const [{ value, onChange, ...field }, { error, touched }, { setValue, setTouched }] =
    useField<number>(name)

  return (
    <Select
      id={name}
      value={value}
      onChange={(value: any) => {
        setValue(value)
        setTouched(true)
      }}
      label={label}
      disabled={disabled !== undefined ? disabled : isSubmitting}
      invalid={!!error && !!touched}
      innerProps={field}
      error={error}
      all={false}
      size={size}
      options={options}
      {...props}
    />
  )
}
