import React from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCreateContract, useReferentiel } from 'api'
import { ContractForm } from './ContractForm'
import { schema } from './contractSchema'
import { addNotification } from 'notification'

export const ContractCreate: React.FC<{ done: () => void }> = ({ done }) => {
  const { t } = useTranslation()
  const [mutate, { data, status }] = useCreateContract()
  const referentiel = useReferentiel()
  const history = useHistory()
  const [created, setCreated] = React.useState(false)

  const supplierGazel = 2278
  const contratType = 1
  const france = 1
  const euro = 21

  React.useEffect(() => {
    if (!data || created) return
    setCreated(true)
    history.push(`/contrat/${data.Id}/informations`)
    setTimeout(done)
    addNotification({
      type: 'success',
      message: t('contract.infos.createSuccess', { name: data.Nom }),
    })
  }, [created, data, done, history, t])

  const values: Partial<Contract> = {
    Nom: '',
    Complement: '',
    Type: contratType,
    Nrj: referentiel.ListeEnergie[0].value,
    DebutLivraison: moment().add(1, 'year').startOf('year').format(),
    FinLivraison: moment().add(1, 'year').endOf('year').format(),
    PaysId: france,
    Devise: euro,
    FournisseurId: supplierGazel,
    DateSignature: moment().format(),
    DelaiReglement: null,
  }

  const submit = React.useCallback(
    async (values, actions) => {
      await mutate({ contract: values })
      actions.setSubmitting(false)
    },
    [mutate],
  )

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema(t)}
      onSubmit={submit}
    >
      {(props) => <ContractForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
