import React from 'react'
import { Form, Button, CustomInput, Label,FormGroup} from 'reactstrap'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'hooks'
import {  DateField, SelectField } from 'components/form'
import { useReferentiel } from 'api'
import { ConsoBody } from 'types/api/ConsoBody'
import ChartComponent from "../../ChartComponent"
import { FaDownload ,FaUpload} from 'react-icons/fa'
import { addNotification } from 'notification'

interface Props extends FormikProps<Partial<ConsoBody>> {
  isLoading: boolean,
  handle:Function,
  displayCurve:Function,
  peeClefId:string,
  dataPrevu:Array<[number, number]>|null,
  dataReel:Array<[number, number]>|null,
}

export const ContratConsommationForm: React.FC<Props> = ({ isLoading, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const { ListCHRPas, ListeTypeDeConso, ListDesUnites, ListTypeFormatCsv} = useReferentiel()
  const inputRef =  React.useRef<HTMLInputElement>(null)
  
  React.useEffect(() => {
    const fetchData = async () => {
        await props.displayCurve(props.values);
       };
    fetchData();
  }, [props.values]);
  
  const includeFile =async (event: React.ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files?.[0];
    if(file){
      try{

        if(file.size>30000000){
          addNotification({
            type: 'danger',
            title: t('contract.consommationPopover.sizeFileConsommation'),
            message:t('contract.consommationPopover.sizeFileConsommationNotif') ,
          })
          return
        }
        else{
          props.values.file?.forEach((value, key) => {
            props.values.file?.delete(key);
          });
          props.values.file?.append('file', file, file.name)

        }
      }
      catch(error){
        console.error(`Error uploading file: ${error}`);
      }
    }
  }
  
  //Show only Prévu/Réel
  const listCHRPas=[...ListCHRPas].filter((o) => (o.value != 9 ))
  const listeConsommationType = [...ListeTypeDeConso].filter((o) => (o.value == 2 || o.value == 6))
  return (
    <>
    <Form
        css={{
        section: {
          display: 'grid',
          gridGap: theme.spacing,
          gridTemplateColumns: 'repeat(2, 2fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 0.5,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
           display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.1rem' },
          },
          marginBottom: '0em'
        },
      }}
    >
      <section>
        <SelectField name="Type" label={t('contract.consommationPopover.type')}  options={listeConsommationType} />
        <DateField name="Debut" label={t('contract.consommationPopover.debut')}   />
        <DateField name="Fin" label={t('contract.consommationPopover.fin')} />
        <SelectField
          name="Unite"
          label={t('contract.consommationPopover.unite')}
          options={ListDesUnites}
        />
        <SelectField name="Pas" label={t('contract.consommationPopover.pas')} options={listCHRPas} />
        <SelectField name="Csv" label={t('contract.consommationPopover.format')}  options={ListTypeFormatCsv} />
        <div>
        <Button className="btn btn-secondary" color="secondary" onClick={()=>{props.handle(props.values)}} >
          <span><FaDownload /> {t('contract.consommationPopover.downloadTemplate')} </span>
        </Button>
        </div>
      </section>
      <section>
      <Label for='docs-upload-inputConso' css={{ padding: 0 }}>
            {t('contract.consommationPopover.requiredFieldFile')}
      </Label>
      </section>
      <section>
      
          <CustomInput
            name="file"
            type="file"
            id="docs-upload-inputConso"
            label={t('contract.docs.selectFile')}
            innerRef={inputRef}
            onChange={includeFile}
          />
        <Button className="btn btn-secondary" color="secondary" onClick={(e)=>{props.handleSubmit()}} >
          <span><FaUpload />{t('contract.consommationPopover.uploadReferenceFile')}</span>
        </Button>
        </section>
   </Form>
   <ChartComponent  dataReel={props.dataReel} dataPrevu={props.dataPrevu} />
   
    </>
  )
} 
