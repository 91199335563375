import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'

const ONE_DAY = 86400000

export const useSuppliers = () => {
  const ready = useSelector(getReady)
  const { data } = useQuery<Supplier[], string>(
    ready && 'suppliers',
    () => superFetch({ url: 'fournisseur/ref' }),
    { staleTime: ONE_DAY },
  )

  const options = useMemo(() => {
    if (!data) return []
    return data.map((s) => ({
      value: s.id,
      label: s.libelle,
    }))
  }, [data])

  return options
}
