export * from './user'
export * from './referentiel'
export * from './suppliers'
export * from './organisation'
export * from './contracts'
export * from './contract'
export * from './contractIat'
export * from './contractDocs'
export * from './contractOptions'
export * from './contractPerimetre'
export * from './lots'
export * from './lot'
export * from './lotPerimetre'
export * from './lotOptions'
export * from './lotTarif'

export const queryConfig = {
  retry: 2,
  staleTime: 2000,
  refetchAllOnWindowFocus: false,
  suspense: true,
}
