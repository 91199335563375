import React from 'react'
import flatMap from 'lodash/flatMap'
import keyBy from 'lodash/keyBy'
import { useTranslation } from 'react-i18next'

import { useContractPerimetre, useOrganisation, isUor } from 'api'
import { useCurrentContract } from 'hooks'
import { ImportDialog } from 'components/atoms'

export const LotPerimetreImport: React.FC<{
  close: () => void
  setData: (data: any) => void
}> = ({ close, setData }) => {
  const { t } = useTranslation()
  const columns = [{ id: 'PeeClef', label: t('global.pee') }]

  const organisation = useOrganisation()
  const ctr = useCurrentContract()

  const contractPerimetre = useContractPerimetre()
  const cpIds = contractPerimetre.map((pee) => pee.id)

  const peesByClef = React.useMemo(() => {
    if (!organisation) return []
    const getPees: (node: TreeNode) => any = (node) =>
      isUor(node)
        ? [
            ...(node.UorEnfants ? flatMap(node.UorEnfants, getPees) : []),
            ...(node.UorSites ? flatMap(node.UorSites, getPees) : []),
          ]
        : (node.SitPees ?? [])
            .filter((pee) => cpIds.includes(pee.id) && pee.PeeNrjId === ctr?.CtrNrjId)
            .map((pee) => ({
              ...pee,
              PeeSphNom: node.SphNom,
            }))

    return keyBy(getPees(organisation), 'PeeClef')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation])

  const setPeeData = React.useCallback(
    (tableData) => {
      const data = tableData
        .map((row: any) => {
          return peesByClef[row.PeeClef]
        })
        .filter((x: any) => x)
      setData(data)
    },
    [setData, peesByClef],
  )

  const isValidCell = React.useCallback((value) => peesByClef[value] !== undefined, [peesByClef])

  return (
    <ImportDialog setData={setPeeData} close={close} isValidCell={isValidCell} columns={columns} />
  )
}
