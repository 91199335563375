import React from 'react'

import { Table } from 'components/atoms'
import { DownloadCell } from './DownloadCell'
import { DeleteCell } from './DeleteCell'

const iconColumn = {
  width: 14,
  disableResizing: true,
  disableSortBy: true,
  align: 'center',
}

export const DocsTable: React.FC<any> = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        ...iconColumn,
        accessor: 'Id',
        Cell: DeleteCell,
      },
      {
        Header: 'Document',
        accessor: 'DocLibelle',
      },
      {
        ...iconColumn,
        accessor: 'Filename',
        Cell: DownloadCell,
      },
    ],
    [],
  )

  return <Table data={data} columns={columns} maxHeight={300} showHeader={false} />
}
