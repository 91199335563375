import * as Yup from 'yup'
import { TFunction } from 'i18next'

export const schema = (t: TFunction) => () =>
  Yup.object().shape(
    {
      Nom: Yup.string().required(t('global.requiredField')),
      Complement: Yup.string(),
      Type: Yup.number().required(t('global.requiredField')),
      Nrj: Yup.number().required(t('global.requiredField')),
      DebutLivraison: Yup.date()
        .when(
          'FinLivraison',
          (FinLivraison: any, schema: any) =>
            FinLivraison && schema.max(FinLivraison, t('global.startDateError')),
        )
        .required(t('global.requiredField')),
      FinLivraison: Yup.date()
        .when(
          'DebutLivraison',
          (DebutLivraison: any, schema: any) =>
            DebutLivraison && schema.min(DebutLivraison, t('global.endDateError')),
        )
        .required(t('global.requiredField')),
      PaysId: Yup.number().required(t('global.requiredField')),
      Devise: Yup.number().required(t('global.requiredField')),
      FournisseurId: Yup.number().required(t('global.requiredField')),
      DateSignature: Yup.date().nullable(),
      DelaiReglement: Yup.number().nullable(),
    },
    [['DebutLivraison', 'FinLivraison']],
  )
