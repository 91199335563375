import React from 'react'
import sortBy from 'lodash/sortBy'

import { List, isNode } from './Tree.types'
import { TreeNode } from './TreeNode'
import { TreeLeaf } from './TreeLeaf'
import { useTreeContext } from './TreeContext'

interface Props extends List {
  isExpanded?: boolean
}

export const TreeList: React.FC<any> = React.memo(({ tree, isExpanded = true, ...props }) => {
  const ctx = useTreeContext()

  const sortedTree = React.useMemo(
    () => sortBy(tree, [(item) => !isNode(item), (item) => item.label]),
    [tree],
  )

  if (!isExpanded) return null

  return (
    <div css={{ padding: ctx.type === 'radio' ? '.5rem 0' : '.1rem 0' }} {...props}>
      {sortedTree.map((item) =>
        isNode(item) ? (
          <TreeNode key={item.value} {...item} />
        ) : (
          <TreeLeaf key={item.value} {...item} />
        ),
      )}
    </div>
  )
})
