import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getContractId, getLotId } from 'store/selection'

export const useLot = (id?: number) => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)
  const selectedLotId = useSelector(getLotId)
  const lotId = id || selectedLotId

  const { data } = useQuery<Lot, AnyQueryKey>(
    ready && contractId !== null && lotId !== null && ['lot', { contractId, lotId }],
    () =>
      superFetch({
        url: `contrats/${contractId}/lots/infos/${lotId}`,
      }),
  )

  return data
}

export const useUpdateLot = () => {
  const contractId = useSelector(getContractId)
  const lotId = useSelector(getLotId)

  return useMutation<Lot, { lot: Partial<Lot> }>(
    ({ lot }) =>
      superFetch({
        url: `contrats/${lot.IdContrat}/lots/infos/${lot.Id}`,
        method: 'PUT',
        body: lot,
      }),
    {
      useErrorBoundary: false,
      onSuccess: () => {
        queryCache.refetchQueries(['lot', { contractId, lotId }])
        queryCache.refetchQueries(['lots', { contractId }])
      },
    },
  )
}

export const useCreateLot = () => {
  const contractId = useSelector(getContractId)

  return useMutation<Lot, { lot: Partial<Lot> }>(
    ({ lot }) =>
      superFetch({
        url: `contrats/${lot.IdContrat}/lots/infos`,
        method: 'POST',
        body: lot,
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['lots', { contractId }])
      },
    },
  )
}

export const useDeleteLot = (contractId: number, lotId: Number) => {
  return useMutation<void>(
    () =>
      superFetch({
        url: `contrats/${contractId}/lots/infos/${lotId}`,
        method: 'DELETE',
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.setQueryData(['lot', { contractId, lotId }], null)
        queryCache.refetchQueries(['lots', { contractId }])
      },
    },
  )
}
